.tabs {
    &-tab {
        visibility: hidden;
        z-index: -999;
        position: fixed;
        top: 0;
        left: 0;
        pointer-events: none;
        opacity: 0;

        &.tabs-active {
            visibility: visible;
            z-index: 1;
            position: static;
            top: auto;
            left: auto;
            pointer-events: all;
            animation: fadeIn 0.2s ease-out;
            opacity: 1;
        }
    }

    &-nav {
        font-size: 0;
        text-align: center;
        margin-bottom: 40px;

        @include bp(md) {
            margin-bottom: 30px;
        }

        &-item {
            display: inline-block;
            font-family: $font1;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            text-align: center;
            width: auto;
            color: $color-dark;
            @include anim-f(all);
            padding: 5px 15px;
            border: 1px solid $color-green;
            border-radius: 6px;
            margin-right: 30px;
            min-width: 80px;

            &:last-child {
                margin-right: 0;
            }

            &.tabs-active,
            &:hover {
                color: $color-green;
            }

            &:not(.tabs-active){
                border-color: transparent;
            }
        }
    }
}
