$oneDaySize: 100% / 7;

.form_default {
    &--datepicker {
        &-trigger {
            display: inline-block;
            width: auto;
            border: 1px solid rgba($color-green, 0.4);
            box-sizing: border-box;
            border-radius: 7px;
            color: $color-dark;
            @include anim-f(border, color);
            padding: 3px 33px 3px 7px;
            position: relative;
            font-weight: bold;
            font-size: 12px;
            line-height: 150%;
            outline: none;

            &:after {
                @include pseudos;
                @extend %icon-base;
                @extend %icon-calendar;
                position: absolute;
                top: 4px;
                right: 4px;
            }

            &:hover,
            &:focus {
                border-color: $color-green;
                color: $color-green;
            }
        }

        &-popup {
            max-width: 363px;

            @include bp(sm) {
                padding: 40px 30px !important;
            }
        }

        &-calendar {
            font-size: 0;
            line-height: 0;
            width: 100%;

            &:not(:last-child) {
                margin-bottom: 24px;
            }

            &-month {
                text-align: center;
                margin-bottom: 20px;

                & > span {
                    display: inline-block;
                    width: 170px;
                    text-align: center;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 150%;
                    padding: 0 10px;
                    vertical-align: middle;
                }

                & > button {
                    width: 20px;
                    height: 20px;
                    display: inline-block;
                    vertical-align: middle;
                    position: relative;
                    cursor: pointer;

                    &:before {
                        @include pseudos;
                        @include arrow(7px, 1px, $color-dark, right);
                        top: 6px;
                        right: 8px;
                        position: absolute;
                        @include anim-f(border);
                    }

                    &:hover {
                        &:before {
                            border-color: $color-green;
                        }
                    }

                    &:disabled {
                        pointer-events: none;

                        &:before {
                            border-color: rgba($color-dark, 0.5);
                        }
                    }

                    &:first-child {
                        transform: translateZ(0) scaleX(-1);
                        transform-origin: 50% 50%;
                    }
                }
            }

            &-weekdays {
                width: auto;
                margin: 0 -14px;
                padding-bottom: 8px;

                & > * {
                    font-style: normal;
                    font-weight: 800;
                    font-size: 12px;
                    line-height: 150%;
                    text-align: center;
                    text-transform: uppercase;
                    color: rgba($color-green, 0.6);
                    width: $oneDaySize;
                    display: inline-block;
                }
            }

            &-select {
                width: auto;
                margin: 0 -14px;

                & > * {
                    width: $oneDaySize;
                    display: inline-block;
                    vertical-align: top;
                    text-align: center;
                }

                label {
                    display: inline-block;
                }

                input[type="radio"] {
                    @include hideField;

                    & ~ span {
                        display: inline-block;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 1.5em;
                        text-align: center;
                        width: 34px;
                        height: 34px;
                        border-radius: 50%;
                        background-color: $color-light;
                        @include anim-f(background, color);
                        cursor: pointer;
                        margin: 2px 0;
                        padding: 6px 0 7px;

                        &:hover {
                            color: $color-green;
                        }
                    }

                    &:checked ~ span {
                        background-color: $color-green;
                        color: white;
                    }

                    &:disabled ~ span {
                        pointer-events: none;
                        color: rgba($color-dark, 0.5);
                    }
                }
            }
        }
    }
}
