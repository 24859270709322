.page_main {
    @extend %header-transparent;
}

.block_steps {
    padding: 80px 0;

    @include bp(lg) {
        padding: 160px 0;
    }

    .block_default--header {
        @include bp-to(md) {
            margin-bottom: 30px;
        }

        @include bp(lg) {
            text-align: center;

            p {
                max-width: 430px;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    &--slider {
        @include bp(lg) {
            max-width: 1100px;
            margin-left: auto;
            margin-right: auto;
        }

        .swiper-container {
            overflow: visible !important;
        }

        &-item {
            width: 250px;
            height: 208px;
            background: $color-light;
            box-shadow: 4px 8px 30px rgba($color-dark, 0.1);
            border-radius: 8px;
            margin-right: 40px;
            position: relative;
            text-align: center;
            font-family: $font1;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 150%;
            color: rgba($color-dark, 0.6);
            padding: 30px 10px;
            opacity: 0;
            @include anim-m(opacity);

            @include bp(md) {
                width: 324px;
                height: 230px;
                padding: 40px 14px 30px;
                margin-right: 64px;
            }

            @for $i from 1 to 4 {
                &:nth-child(#{$i}) {
                    transition-delay: #{0.1s * $i};
                }
            }

            .anim-inViewport &{
                opacity: 1;
            }

            &:after {
                @include pseudos;
                @extend %icon-base;
                @extend %icon-arrow-right;
                position: absolute;
                left: 100%;
                top: 50%;
                margin-top: -8px;
                margin-left: 10px;

                @include bp-to(md) {
                    background-position: 100% 50%;
                    background-size: auto 100%;
                    width: 20px;
                }

                @include bp(md) {
                    margin-left: 17px;
                }
            }

            &:last-child {
                margin-right: 0;

                &:after {
                    display: none;
                }
            }

            .icon {
                display: block;
                width: 60px;
                height: 60px;
                background: rgba($color-purple, 0.1);
                border-radius: 20px;
                margin: 0 auto 16px;
                line-height: 58px;
                text-align: center;

                @include bp(md) {
                    margin-bottom: 24px;
                }

                img {
                    display: inline-block;
                    vertical-align: middle;
                }
            }

            h3 {
                @extend %header-medium2;
                margin-bottom: 8px;
                color: $color-dark;
            }
        }
    }
}

.block_history_improve {
    padding: 0 !important;
    overflow: hidden;
    position: relative;

    .wrapper {
        @include bp(lg) {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
        }
    }

    .block_default--header {
        position: relative;
        color: $color-light;
        padding: 60px 0 105px;
        z-index: 1;
        margin-bottom: 0;

        @include bp(lg) {
            width: 55%;
            padding: 70px 60px 60px;
            margin-top: 50px;
        }

        .btn {
            margin-top: 6px;
            width: 200px;

            @include bp(lg) {
                margin-top: 10px;
            }
        }

        &:before {
            @include pseudos;
            width: 100vw;
            height: 100%;
            position: absolute;
            left: 50%;
            top: 0;
            margin-left: -50vw;
            z-index: -1;
            background-color: $color-purple;

            @include bp(lg) {
                border-radius: 8px 0 0 8px;
                margin-left: 0;
                left: 0;
                width: 100%;
            }
        }
    }

    .block_default--content {
        position: relative;

        @include bp(lg) {
            width: 45%;
        }
    }

    &--notice {
        position: absolute;
        z-index: 2;
        padding: 30px 0 30px 30px;
        right: 0;
        top: 0;
        transform: translateY(-50%);
        font-family: $font1;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 110%;
        color: $color-purple;

        @include bp(lg) {
            font-size: 24px;
            width: 100%;
            top: auto;
            bottom: 50px;
            left: -30px;
            padding: 35px 30px;
            transform: none;
        }

        @include bp(xl) {
            left: -74px;
        }

        strong,
        b {
            color: $color-green;
        }

        &:before {
            @include pseudos;
            width: 100vw;
            height: 100%;
            left: 0;
            top: 0;
            border-radius: 8px 0 0 8px;
            box-shadow: 8px 8px 30px rgba($color-dark, 0.2);
            background-color: $color-light;
            position: absolute;
            z-index: -1;

            @include bp(lg) {
                width: 100%;
                border-radius: 8px;
            }
        }
    }

    &--img {
        position: relative;
        z-index: 1;
        width: 100vw;
        height: 350px;
        overflow: hidden;
        left: 50%;
        margin-left: -50vw;

        @include bp(sm, md) {
            height: 450px;
        }

        @include bp(md, lg) {
            height: 520px;
        }

        @include bp(lg) {
            width: 588px;
            height: 551px;
            border-radius: 8px;
            margin-left: 0;
            left: 0;
        }

        img {
            position: absolute;
            width: 100%;
            height: 140%;
            top: -10%;
            left: 0;
            object-fit: cover;
            object-position: 50% -20%;

            @include bp(md) {
                object-position: 50% 20%;
            }
        }
    }
}
