.page_lk{
    &--change_password{
        .form_default{
            margin-top: 38px;

            @include bp(md){
                margin-top: 36px;
            }

            &--timer{
                margin-bottom: 0;
                margin-top: -33px;

                @include bp(md){
                    margin-bottom: -28px;
                    margin-top: -21px;
                }

                & > button{
                    color: $color-green;

                    &:hover{
                        color: $color-purple;
                    }
                }
            }
        }

        &-foot{
            margin-top: 30px;

            @include bp(md){
                margin-top: 40px;
            }

            .btn{
                min-width: 0;
            }
        }

        .page_lk--layout-section-header{
            margin-bottom: 14px;

            @include bp(md){
                margin-bottom: 20px;
            }
        }

        .block_text{
            max-width: 636px;
        }
    }
}