.page_register {
    main {
        @include bp(md) {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            & > * {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }
    }
}

.layout_enter {
    padding: 30px 0 50px;

    @include bp(md) {
        padding: 40px 0;
    }

    p {
        & + .form_default {
            margin-top: 30px;
        }
    }

    h2 {
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 110%;
        text-align: center;
        margin-bottom: 40px;

        @include bp(md) {
            margin-bottom: 30px;
        }
    }

    h3 {
        display: block;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 110%;
        text-align: center;
        margin-top: 30px;
        margin-bottom: 14px;
    }

    h4 {
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 22px;
        display: block;
        margin: 60px 0 38px;

        @include bp(md) {
            margin: 30px 0 20px;
        }
    }

    .form_default {
        .btn {
            min-width: 170px;
        }
    }

    &--buttons_row {
        margin-bottom: 30px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: baseline;

        a:not(.btn) {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 150%;
            text-decoration: underline;

            &:hover {
                color: $color-green;
            }
        }
    }

    &--container {
        @include bp(md) {
            background: $color-light;
            box-shadow: 4px 4px 30px rgba($color-purple, 0.1);
            border-radius: 4px;
            margin: 0 auto;
            max-width: 606px;
            padding: 50px 97px 60px;
        }

        .tabs-tab > p,
        & > p {
            margin: 14px 0;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 150%;
            text-align: center;

            @include bp(md) {
                margin-left: -15px;
                margin-right: -15px;
            }

            b,
            strong {
                font-weight: 700;
            }
        }
    }

    .nouislider {
        margin-bottom: 30px;
    }

    &--calc_foot {
        margin-top: 30px;
        border-top: 1px solid rgba($color-purple, 0.15);
        padding-top: 16px;

        p {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-end;
            margin-bottom: 11px;

            &:last-child {
                margin-bottom: 0;
            }

            & > * {
                width: 120px;

                &:first-child {
                    margin-right: 50px;
                }
            }

            & > small {
                font-family: $font1;
                font-style: normal;
                font-weight: 300;
                font-size: 14px;
                line-height: 19px;
                color: rgba($color-dark, 0.6);
            }

            & > span {
                font-size: 0;
                line-height: 0;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: baseline;

                & > * {
                    font-family: $font1;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 22px;
                }

                small {
                    font-size: 0.8em;
                }

                img {
                    margin-right: 15px;
                    max-width: calc(33.333% - 10px);

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    &--checkboxes {
        margin-top: -8px;
        margin-bottom: 30px;

        @include bp(md) {
            margin-top: -6px;
        }

        .input_container {
            margin-bottom: 20px;

            @include bp(md) {
                margin-bottom: 10px;
            }

            &.d-none {
                display: none;
            }
        }
    }

    .row {
        & > .col {
            @include bp(md) {
                width: auto !important;
                flex-basis: auto !important;

                &:first-child {
                    flex-grow: 0 !important;
                    flex-shrink: 0 !important;
                }

                &:last-child {
                    flex-grow: 1 !important;
                    flex-shrink: 1 !important;
                }
            }
        }

        .btn {
            @include bp(md) {
                width: 100%;
                padding-left: 0;
                padding-right: 0;
            }
        }

        .btn[type="submit"] {
            @include bp(md) {
                width: 170px;
            }
        }
    }

    &--next {
        margin-top: 30px;
        text-align: center;

        @include bp(md) {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
        }

        .input_container + & {
            margin-top: -8px;

            @include bp(md) {
                margin-top: 30px;
            }
        }

        .btn {
            margin-bottom: 30px;

            &:last-child {
                margin-bottom: 0;
            }

            @include bp(md) {
                width: auto;
                display: inline-block;
                min-width: 218px !important;
            }
        }
    }
}

.popup_accept_number {
    padding: 80px 16px 60px;

    @include bp(md) {
        width: 600px;
        padding: 70px 60px 60px;

        .btn {
            max-width: 218px;
        }
    }

    .form_default {
        margin-top: 38px;
    }
}
