.form_default {
    input[type="checkbox"],
    input[type="radio"] {
        @include hideField;

        & ~ .input_container--error {
            left: 34px;
        }

        & ~ .input_container--label {
            transform: none;
            position: relative;
            padding-left: 34px;
            cursor: pointer;
            display: inline-block;
            font-family: $font1;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 150%;
            pointer-events: all;
            opacity: 1;
            top: 0;
            left: 0;
            white-space: normal;
            text-overflow: none;
            width: auto;
            max-width: none;
            right: auto;
            overflow: visible;

            a,
            button {
                text-decoration: underline;
                font-size: inherit;
                font-weight: inherit;
                line-height: inherit;
                color: inherit;
                display: inline !important;
                width: auto;

                &:hover {
                    text-decoration: none;
                }
            }

            &:before {
                @include pseudos;
                position: absolute;
                left: 0;
                top: -1px;
                width: 20px;
                height: 20px;
                border: 1px solid rgba($color-purple, 0.2);
                background-color: $color-light;
                @include anim-f(border, background);
            }

            &:after {
                @include pseudos;
                position: absolute;
                opacity: 0;
                @include anim-f(background, opacity);
            }
        }

        &:focus ~ .input_container--label,
        & ~ .input_container--label:hover {
            &:before {
                border-color: $color-green;
            }
        }

        &:checked ~ .input_container--label {
            &:after {
                opacity: 1;
            }
        }
    }

    input[type="checkbox"] {
        & ~ .input_container--label {
            cursor: pointer;

            &:before {
                border-radius: 2px;
            }

            &:after {
                transform-origin: 50% 50%;
                transform: rotate(-45deg);
                width: 11px;
                height: 5px;
                border-style: solid;
                border-width: 0 0 1px 1px;
                border-color: $color-green;
                left: 5px;
                top: 5px;
            }
        }
    }

    input[type="radio"] {
        & ~ .input_container--label {
            cursor: pointer;
            padding-left: 32px;
            font-size: 16px;
            line-height: 150%;

            &:before,
            &:after {
                border-radius: 50%;
                margin-top: 2px;
            }

            &:after {
                background-color: $color-purple;
                width: 10px;
                height: 10px;
                top: 4px;
                left: 5px;
            }

            &:before {
                border-width: 2px;
            }
        }

        &:focus ~ .input_container--label,
        & ~ .input_container--label:hover {
            &:before {
                border-color: $color-purple;
            }
        }
    }

    &--field_group-selector {
        width: 24px;
        height: 24px;
        top: 13px;
        right: 100%;
        position: absolute;
        margin: 0 20px 0 0;

        input[type="radio"] {
            & ~ .input_container--label {
                width: 24px;
                height: 24px;
                padding: 0;

                &:before {
                    width: 20px;
                    height: 20px;
                    background-color: $color-light;
                    border: 2px solid rgba($color-dark, 0.3);
                    border-radius: 50%;
                    top: 2px;
                    left: 2px;
                    margin: 0;
                    @include anim-f(border, background);
                }

                &:after {
                    border-radius: 0;
                    border-style: solid;
                    border-width: 0 0 2px 2px;
                    border-color: rgba($color-dark, 0.3);
                    width: 10px;
                    height: 6px;
                    transform-origin: 50% 50%;
                    transform: rotate(-45deg);
                    top: 8px;
                    left: 7px;
                    background-color: transparent;
                    opacity: 1;
                    margin: 0;
                    @include anim-f(border);
                }
            }

            &:focus ~ .input_container--label,
            & ~ .input_container--label:hover {
                &:before {
                    border-color: rgba($color-dark, 0.5);
                }
            }

            &:checked ~ .input_container--label {
                &:before {
                    background-color: rgba($color-green, 0.3);
                    border-color: $color-green;
                }

                &:after{
                    border-color: $color-green;
                }
            }
        }
    }
}
