// i[class^="icon-"] {
//   display: inline-block;
//   vertical-align: middle;
// }


svg.icon{
    stroke-width: 0;
    display: inline-block;
    vertical-align: middle;
    height: 1em;
    line-height: 1em;
}

%icon-base{
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

%icon-arrow-right{
    background-image: url("data:image/svg+xml,%3Csvg width='31' height='16' viewBox='0 0 31 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M30.7071 8.70711C31.0976 8.31658 31.0976 7.68342 30.7071 7.29289L24.3431 0.928932C23.9526 0.538408 23.3195 0.538408 22.9289 0.928932C22.5384 1.31946 22.5384 1.95262 22.9289 2.34315L28.5858 8L22.9289 13.6569C22.5384 14.0474 22.5384 14.6805 22.9289 15.0711C23.3195 15.4616 23.9526 15.4616 24.3431 15.0711L30.7071 8.70711ZM0 9L30 9V7L0 7L0 9Z' fill='%23161724'/%3E%3C/svg%3E%0A");
    width: 31px;
    height: 16px;
}

%icon-plus-green{
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.9961 1.09753H9.00208V9.00298H1.09663V10.997H9.00208V18.9025H10.9961V10.997H18.9016V9.00298H10.9961V1.09753Z' fill='%2322A352'/%3E%3C/svg%3E%0A");
    width: 20px;
    height: 20px;
}

%icon-arrow-left{
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 8.75H4.7875L11.775 1.7625L10 0L0 10L10 20L11.7625 18.2375L4.7875 11.25H20V8.75Z' fill='%23161724'/%3E%3C/svg%3E%0A");
    width: 20px;
    height: 20px;
}

%icon-info{
    background-image: url("data:image/svg+xml,%3Csvg width='3' height='7' viewBox='0 0 3 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.50255 4.79858C1.39891 4.79858 1.30908 4.76932 1.23307 4.71079C1.16398 4.65227 1.12252 4.56741 1.1087 4.45621L0.725208 1.0412L0.714844 0.953413C0.714844 0.771982 0.787396 0.628592 0.9325 0.523245C1.08451 0.412045 1.27453 0.356445 1.50255 0.356445C1.74439 0.356445 1.94132 0.420824 2.09333 0.549582C2.24535 0.672487 2.30753 0.839286 2.27989 1.04998L1.8964 4.45621C1.88259 4.56741 1.83767 4.65227 1.76167 4.71079C1.69257 4.76932 1.6062 4.79858 1.50255 4.79858ZM1.50255 6.64216C1.28144 6.64216 1.09833 6.58071 0.953229 6.4578C0.808125 6.32904 0.735573 6.17102 0.735573 5.98374C0.735573 5.79646 0.808125 5.64136 0.953229 5.51846C1.09833 5.3897 1.28144 5.32532 1.50255 5.32532C1.72366 5.32532 1.90677 5.3897 2.05187 5.51846C2.19698 5.64136 2.26953 5.79646 2.26953 5.98374C2.26953 6.17102 2.19698 6.32904 2.05187 6.4578C1.90677 6.58071 1.72366 6.64216 1.50255 6.64216Z' fill='%2322A352'/%3E%3C/svg%3E%0A");
    width: 3px;
    height: 7px;
}

%icon-calendar{
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.3' d='M3 3H13V14H3V3Z' fill='%2327AE60'/%3E%3Cpath d='M4.66667 7.33301H6V8.66634H4.66667V7.33301ZM14 3.99967V13.333C14 14.0663 13.4 14.6663 12.6667 14.6663H3.33333C2.59333 14.6663 2 14.0663 2 13.333L2.00667 3.99967C2.00667 3.26634 2.59333 2.66634 3.33333 2.66634H4V1.33301H5.33333V2.66634H10.6667V1.33301H12V2.66634H12.6667C13.4 2.66634 14 3.26634 14 3.99967ZM3.33333 5.33301H12.6667V3.99967H3.33333V5.33301ZM12.6667 13.333V6.66634H3.33333V13.333H12.6667ZM10 8.66634H11.3333V7.33301H10V8.66634ZM7.33333 8.66634H8.66667V7.33301H7.33333V8.66634Z' fill='%2322A352'/%3E%3C/svg%3E%0A");
    width: 16px;
    height: 16px;
}