.form_default {
    select {
        display: block;
        width: 100%;
        height: 35px;
        border-radius: 7px;
        border: 1px solid rgba($color-purple, 0.2);
        @include anim-f(border);
        cursor: pointer;
        @extend %form-label;
        background-color: $color-light;
        padding: 8px 30px 8px 13px;
        height: 40px;
        appearance: none;
        background-image: url("data:image/svg+xml,%3Csvg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.825 0.158203L5 3.97487L1.175 0.158203L0 1.3332L5 6.3332L10 1.3332L8.825 0.158203Z' fill='%23323232'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: calc(100% - 21px) 50%;
        text-overflow: ellipsis;

        &:focus,
        &:hover {
            border-color: $color-purple !important;
        }

        &.validation-touched:invalid {
            border-color: $color-red;
        }

        &.thin {
            font-weight: 600;
            font-size: 16px;
            line-height: 150%;
            padding: 8px 40px 8px 12px;
            height: 40px;
            background-color: rgba($color-dark, 0.05);
            border-color: transparent;
        }
    }
}
