.page_lk{
    &--calculate {
        .nouislider {
            & + .nouislider {
                margin-top: 30px;

                @include bp(md) {
                    margin-top: 40px;
                }
            }
        }

        &-total {
            display: table;
            width: 100%;
            border-top: 1px solid rgba($color-purple, 0.15);
            padding-top: 18px;
            margin-bottom: 32px;

            .calculate_conditions {
                position: absolute;
                display: block;
                color: rgba(22,23,36,0);
                font-size: 0.8em;
                bottom: auto;
                transition: color 0.5s linear;

                @include bp(md) {
                    bottom: 3.5rem;
                }

                &--expanded {
                    color: rgba(22, 23, 36, 1);
                }

            }

            @include bp(md) {
                padding-top: 0;
                border-top: none;
                margin-bottom: 16px;
            }

            @include bp(xl) {
                margin-left: 40px;
                width: auto;

                & ~ .btn {
                    margin-left: 40px;
                    width: calc(100% - 40px);
                }
            }

            p {
                display: table-row;
                width: 100%;

                & > * {
                    display: table-cell;
                    vertical-align: baseline;
                    padding-top: 0;
                    padding-bottom: 8px;

                    @include bp(md) {
                        padding-bottom: 14px;
                    }
                }

                & > small {
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 19px;
                    opacity: 0.6;
                    width: 43%;
                }

                & > span {
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 22px;
                    padding-left: 10px;

                    small {
                        font-size: 0.7em;
                    }
                }
            }
        }
    }
}