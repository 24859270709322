%form-label {
    font-family: $font1;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
}

.form_default {
    display: block;

    * {
        outline: none;
    }

    &.submitting {
    }

    &.submitted {
    }

    .g-recaptcha {
    }

    &--fields_alt {
        #{$text-inputs},
        select {
            border-color: $color-light;

            &.focused ~ .input_container--label {
                color: $color-light;
            }
        }

        input[type="checkbox"],
        input[type="radio"] {
            & ~ .input_container--label {
                color: $color-light;

                &:before {
                    border-color: $color-light;
                }
            }

            &:focus ~ .input_container--label,
            & ~ .input_container--label:hover {
                &:before {
                    border-color: $color-green;
                }
            }
        }

        input[type="file"] {
            & ~ .input_container--label {
                color: $color-light !important;

                svg {
                    fill: $color-light !important;
                }
            }

            &:focus ~ .input_container--label,
            & ~ .input_container--label:hover {
                color: $color-green !important;

                svg {
                    fill: $color-green !important;
                }
            }

            & ~ .input_container--files {
                color: $color-light !important;

                button {
                    &:before,
                    &:after {
                        background-color: $color-light !important;
                    }

                    &:hover {
                        &:before,
                        &:after {
                            background-color: $color-green !important;
                        }
                    }
                }
            }
        }
    }

    &--timer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: baseline;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 150%;
        margin-bottom: 30px;
        margin-top: 10px;

        .input_container + & {
            margin-top: -28px;

            @include bp(md) {
                margin-top: -16px;
            }
        }

        button {
            text-decoration: underline;
            @include anim-f(all);
            font-size: inherit;
            line-height: inherit;

            &:disabled {
                opacity: 0.5;
            }

            &:hover {
                color: $color-green;
            }
        }
    }

    fieldset {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;

        .input_container {
            width: auto;
            margin-bottom: 0 !important;
            margin-top: 12px;
            margin-right: 32px;

            &:last-child {
                margin-right: 0;
            }
        }

        legend {
            display: block;
            position: static;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 150%;
        }
    }

    &--selectgroup {
        position: relative;
        z-index: 1;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;

        & > input,
        & > textarea,
        & > select,
        & > .input_container {
            margin-right: 8px;
            width: auto !important;
            flex-grow: 1;
            margin-bottom: 0 !important;
            flex-shrink: 1;
            min-width: 0;
            box-sizing: border-box;

            &:last-of-type {
                margin-right: 0;
            }

            & > input {
                max-width: 100%;
            }
        }

        select{
            padding-left: 6px !important;
            padding-right: 15px !important;
            background-position: calc(100% - 7px) 50% !important;
            background-size: 7px 5px;
        }
    }

    &--img_preview {
        position: relative;
        display: block;
        width: 143px;
        height: 135px;
        overflow: hidden;
        border-radius: 7px;

        button {
            @include cross(20px, 2px, $color-dark, 0, $color-green);
            display: block;
            position: absolute;
            width: 20px;
            height: 20px;
            top: 10px;
            right: 10px;
            z-index: 1;
        }

        img {
            @include stretch;
            object-fit: cover;
        }
    }

    &--add {
        display: inline-block;
        width: auto;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        padding-left: 34px;
        position: relative;
        @include anim-f(color);

        &.d-none{
            display: none;
        }

        svg {
            position: absolute;
            top: 0;
            left: 0;
            @include svgcolor($color-dark);
        }

        &:hover {
            color: $color-green;

            svg {
                fill: $color-green;
            }
        }
    }

    &--field_group{
        margin-left: 44px;
        position: relative;
        margin-bottom: 52px;

        @include bp(md){
            margin-bottom: 58px;
        }

        @include bp-to(md){
            .form_default--add{
                margin-left: -44px;
            }
        }

        &.selected{
            #{$text-inputs},
            select{
                border-color: $color-green;
            }
        }
    }

    .binded-card-container {
        margin-top: 30px;
    }
}
