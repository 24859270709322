.block_text {
    @extend %collapse-margins;
    font-family: $font1;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;

    h1 {
        @extend %header-big;
        margin-bottom: 20px;
    }

    h2 {
        @extend %header-medium;
        margin-bottom: 20px;
    }

    h3 {
        @extend %header-medium2;
        margin-bottom: 20px;
        margin-top: 40px;
    }

    h4 {
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        color: $color-purple;
        margin: 40px 0 14px;

        @include bp(md) {
            margin-bottom: 20px;
        }
    }

    .row {
        & > * {
            @extend %collapse-margins;
        }
    }

    li {
        & > * {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        & + p,
        & + ul,
        & + ol {
            margin-top: 0;
        }
    }

    em {
        font-style: normal;
        color: $color-purple;
        font-weight: 700;

        & > a {
            color: inherit;
            text-decoration: none !important;
            color: inherit !important;
        }
    }

    p {
        a {
            @include bp-to(md) {
                &.btn.inline {
                    width: 100%;
                    display: block;
                }
            }

            &:not(.btn) {
                color: $color-green;
                text-decoration: underline;

                &:hover {
                    color: inherit;
                }
            }
        }

        img {
            display: block;
            max-width: 100%;
        }
    }

    a.file {
        display: inline-block;
        padding-left: 30px;
        text-decoration: none;
        color: $color-purple;
        position: relative;

        &:hover {
            color: $color-purple-hover;

            svg {
                fill: $color-purple-hover;
            }
        }

        span {
            text-decoration: underline;
            margin-right: 4px;
        }

        small {
            display: inline-block;
            font-size: inherit;
            color: rgba($color-dark, 0.5);
        }

        & > * {
            display: inline-block;
        }

        svg {
            @include svgcolor($color-purple);
            position: absolute;
            left: 0;
            top: 0;
        }
    }

    strong,
    b {
        font-weight: 700;
    }

    .highlighted {
        color: $color-red;
        font-weight: 700;
    }

    p,
    ul,
    ol {
        margin: 1.5em 0;
    }

    ul {
        @extend %list-default;

        &.clear {
            li {
                padding-left: 0;

                &:before {
                    display: none;
                }
            }
        }

        &.alt {
            margin-top: -14px;

            @include bp(md) {
                padding-left: 10px;
            }

            li {
                margin-bottom: 6px;

                &:last-child {
                    margin-bottom: 0;
                }

                &:before {
                    background-color: $color-purple;
                }

                strong {
                    font-weight: 700;
                    color: $color-purple;
                }

                a:not(.btn) {
                    color: $color-purple;
                    text-decoration: underline;
                    font-weight: 700;

                    &[href^="tel"] {
                        text-decoration: none;
                    }

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }

    ol {
        @extend %list-numerated;
    }

    &--conditions {
        background: rgba($color-purple-light, 0.3);
        overflow: hidden;
        border-radius: 8px;
        position: relative;
        z-index: 1;
        padding: 30px;

        @include bp(md) {
            padding: 30px 20px;
        }

        & > h3 {
            font-weight: bold !important;
            font-size: 20px !important;
            line-height: 27px !important;
            font-family: $font1;
            margin-bottom: 14px !important;
            margin-top: 0 !important;

            @include bp(md) {
                margin-bottom: 10px !important;
            }
        }

        &:before,
        &:after {
            @include pseudos;
            position: absolute;
            z-index: -1;
        }

        &:before {
            width: 41.01px;
            height: 41.01px;
            top: 0;
            right: 70px;
            background: linear-gradient(225deg, rgba($color-light, 0.2) 8.25%, rgba($color-light, 0) 95.46%);
            border-radius: 8px;
            transform: rotate(-135deg);

            @include bp(md) {
                top: -20px;
                right: 120px;
            }
        }

        &:after {
            width: 113.14px;
            height: 113.14px;
            background: linear-gradient(225deg, rgba($color-light, 0.3) 8.25%, rgba($color-light, 0) 95.46%);
            border-radius: 20px;
            transform: rotate(135deg);
            right: -55px;
            top: 15px;

            @include bp(md) {
                width: 171.12px;
                height: 171.12px;
                border-radius: 30px;
                right: -85px;
                top: -30px;
            }
        }

        & > div {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            flex-wrap: wrap;
            margin-bottom: -15px;

            @include bp(md) {
                flex-wrap: nowrap;
                white-space: nowrap;
                margin-bottom: -30px;
            }

            p {
                width: 100%;
                margin-top: 0;
                margin-bottom: 15px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 150%;

                @include bp(md) {
                    width: auto;
                    margin-right: 30px;
                    display: block;
                    margin-bottom: 30px;

                    &:last-child {
                        margin-right: 0;
                    }

                    & > * {
                        display: block;
                    }
                }

                small {
                    font-size: inherit;
                    line-height: inherit;
                    opacity: 0.6;

                    @include bp(md) {
                        margin-bottom: 6px;
                    }
                }

                span {
                    font-weight: bold;
                    font-size: 20px;
                    line-height: 110%;

                    sub {
                        vertical-align: middle;
                        font-size: 12px;
                        line-height: 16px;
                        margin: 0;
                        display: inline-block;
                        margin-top: -5px;
                    }
                }

                & > * {
                    width: calc(50% - 12px);
                }
            }
        }
    }

    &--table {
        @include bp-to(lg) {
            width: 100%;
            display: block;
            overflow-x: auto;
            overflow-y: hidden;

            &-container {
                display: inline-block;
                width: auto;
            }

            table {
                td {
                    white-space: nowrap;
                }
            }
        }
    }

    table {
        min-width: 100%;

        th,
        td {
            vertical-align: middle;
            padding-left: 17px;
            padding-right: 17px;

            &:first-child {
                padding-left: 20px;
            }

            &:last-child {
                padding-right: 20px;
            }
        }

        th {
            background: rgba($color-purple-light, 0.2);
            padding-top: 18px;
            padding-bottom: 18px;
            text-align: left;
            font-weight: 600;
            font-size: 12px;
            line-height: 120%;

            &:first-child {
                border-radius: 4px 0 0 4px;
            }

            &:last-child {
                border-radius: 0 4px 4px 0;
            }
        }

        td {
            padding-top: 15px;
            padding-bottom: 15px;
            border-bottom: 1px solid rgba($color-purple, 0.2);
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 150%;
        }

        tbody {
            tr:first-child {
                td {
                    padding-top: 20px;
                }
            }
        }
    }

    &--pseudotable {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 150%;

        @include bp-to(md) {
            p {
                display: table;
                width: 100%;
                margin-top: 4px;
                border-top: 1px solid rgba($color-purple, 0.2);
                margin-bottom: 0;

                & > * {
                    display: table-row;

                    &:first-child {
                        & > * {
                            padding-top: 10px;
                        }
                    }

                    & > * {
                        display: table-cell;
                        padding-left: 20px;
                        padding-bottom: 6px;

                        &:first-child {
                            padding-left: 0;
                            color: rgba($color-dark, 0.6);
                        }
                    }
                }

                &:first-child {
                    display: none;
                }

                &:nth-child(2) {
                    margin-top: 0;
                    border-top: none;

                    & > * > * {
                        padding-top: 0 !important;
                    }
                }
            }
        }

        @include bp(md) {
            display: table;
            width: auto;
            max-width: 100%;

            & > * {
                display: table-row;
                margin: 0;

                &:first-child {
                    color: rgba($color-dark, 0.6);

                    & > *{
                        padding-top: 0;
                    }
                }

                &:last-child{
                    & > *{
                        padding-bottom: 0;
                    }
                }

                & > * {
                    display: table-cell;
                    padding: 8px 40px;

                    &:first-child{
                        padding-left: 0;
                    }

                    &:last-child{
                        padding-right: 0;
                    }

                    & > span {
                        &:first-child {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
