.anim {
    &-item {
        .is-ie &{
            animation: none !important;
            visibility: visible !important;
        }

        animation-duration: 0.5s;
        animation-timing-function: ease-out;

        &:not(.animate__animated) {
            visibility: hidden;
            animation-play-state: paused;
        }
    }
}
