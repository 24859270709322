$header-big: 100px;
$header-small: 60px;
$header-small-expanded: 96px;

.page_header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: $z-header;

    @include bp(xl) {
        &:before {
            @include pseudos;
            position: absolute;
            width: 100%;
            height: $header-big;
            background-color: $color-light;
            box-shadow: 0px 4px 20px rgba(109, 38, 100, 0.06);
            z-index: -1;
            top: 0;
            left: 0;
        }
    }

    &.fix {
        &:not(.expanded) {
            transform: translateY(-100%) translateZ(0);
            opacity: 0;
            pointer-events: none;
        }

        &.show:not(.expanded) {
            transform: none;
            opacity: 1;
            transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out, max-height 0.3s ease-in-out;
            pointer-events: all;
        }

        &.hide:not(.expanded) {
            transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out, max-height 0.3s ease-in-out;
        }
    }

    &.expanded,
    &.fix {
        position: fixed;
    }

    &--placeholder {
        height: $header-small-expanded;

        @include bp(xl) {
            height: $header-big;
        }
    }

    &--main {
        width: 100%;
        height: $header-small-expanded;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 2;
        background-color: $color-light;

        @include bp-to(xl) {
            @include anim-f(height);
        }

        @include bp(xl) {
            display: block;
            height: 0;
        }

        .fix:not(.expanded) & {
            @include bp-to(xl) {
                height: $header-small;
            }
        }

        .wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        &-logo {
            position: relative;
            display: block;
            font-size: 0;
            line-height: 0;
            height: 35px;

            @include bp(xl) {
                position: absolute;
                left: 18px;
                top: 35px;
            }

            img {
                max-height: 100%;
                margin-left: -48px;
            }
        }

        &-burger {
            width: 36px;
            height: 36px;
            position: relative;
            display: block;
            $burger-line-width: 27px;
            $burger-line-thickness: 3px;
            $burger-line-gap: 5px + $burger-line-thickness;
            $burger-color: $color-dark;

            @include bp(xl) {
                display: none;
            }

            &:before,
            &:after {
                @include pseudos;
                width: $burger-line-width;
                height: $burger-line-thickness;
                background-color: $burger-color;
                position: absolute;
                top: 50%;
                left: 50%;
                margin: #{-$burger-line-thickness/2} #{-$burger-line-width/2};
                transform-origin: 50% 50%;
                @include anim-f(background-color);
            }

            &:before {
                box-shadow: 0 $burger-line-gap 0 0 $burger-color, 0 #{-$burger-line-gap} 0 0 $burger-color;
            }

            .expanded & {
                &:before {
                    box-shadow: none;
                    transform: translateZ(0) rotate(-45deg);
                }

                &:after {
                    transform: translateZ(0) rotate(45deg);
                }
            }
        }
    }

    &--menu {
        height: vh(100);
        width: 100%;
        padding-top: $header-small-expanded;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        transition: visibility 0.001s linear 0.4s, opacity 0.4s ease, transform 0.2s ease-out;
        background-color: $color-light;
        z-index: 1;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: center;
        transform: translateX(-80%);

        @include bp(xl) {
            display: block;
            padding-top: 0;
            transform: none;
            opacity: 1;
            transition: none;
            visibility: visible;
            pointer-events: inherit;
            transform: none;

            &,
            .wrapper,
            &_scroll {
                height: 0;
            }
        }

        @include bp-to(xl) {
            .expanded & {
                visibility: visible;
                opacity: 1;
                transition-delay: 0s;
                pointer-events: all;
                transform: translateX(0);
            }
        }

        &-scroll {
            max-height: 100%;
            overflow-y: auto;
            overflow-x: hidden;
            width: 100%;
            height: 100%;

            @include bp(xl) {
                overflow: visible;
                max-height: none;
            }

            .wrapper {
                flex-shrink: 0;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: stretch;
                height: 100%;

                @include bp(xl) {
                    padding: 0;
                    display: block;
                }
            }
        }

        &-enter {
            @include bp(xl) {
                position: absolute;
                right: 0;
                top: 32px;
            }

            a {
                display: block;
                position: relative;
                font-style: normal;
                font-weight: bold;
                font-size: 20px;
                line-height: 62px;
                width: auto;
                margin: 0 -10px 30px;
                border-top: 1px solid rgba(#6d2664, 0.2);
                border-bottom: 1px solid rgba(#6d2664, 0.2);
                color: $color-green;
                text-align: left;
                padding: 0 10px;
                color: $color-green !important;

                @include bp(xl) {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 15px;
                    line-height: 18px;
                    text-align: center;
                    color: $color-light !important;
                    padding: 11px;
                    background-color: $color-green;
                    border-radius: 6px;
                    @include anim-f(all);
                    border: none;
                    min-width: 160px;
                    margin: 0;

                    &:hover {
                        background-color: $color-green-hover;
                    }
                }
            }
        }

        &-nav {
            margin-top: 0;
            margin-bottom: auto;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 150%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;

            @include bp(xl) {
                flex-direction: row;
                width: auto;
                position: absolute;
                left: 267px;
                top: 62px;
                margin: 0;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;

                a {
                    margin-bottom: 0;
                    margin-right: 35px;

                    &:hover {
                        color: $color-green;
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }

            a {
                margin-bottom: 26px;

                &:last-child {
                    margin-bottom: 0;
                }

                &.active {
                    color: $color-green;
                }
            }
        }

        &-contacts {
            margin-top: 50px;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 110%;
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: flex-start;
            justify-content: flex-start;
            padding-bottom: 30px;

            @include bp(xl) {
                margin: 0;
                font-size: 15px;
                line-height: 20px;
                flex-direction: row;
                position: absolute;
                width: auto;
                left: 267px;
                top: 30px;
                padding: 0;

                a {
                    margin-bottom: 0;
                    margin-right: 35px;

                    &:hover {
                        text-decoration: underline;
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }

            a {
                margin-bottom: 14px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

%header-transparent {
    .page_header {
        &--main {
            background-color: transparent;
        }

        a {
            color: $color-light;
        }

        &.fix,
        &.expanded {
            color: $color-dark;

            &:before {
                opacity: 1;
            }

            .page_header--main-logo,
            .page_header--main-burger {
                filter: grayscale(0) brightness(1) invert(0);
            }

            .page_header--main {
                background-color: $color-light;
            }

            a {
                color: $color-dark;
            }
        }

        &:before {
            opacity: 0;
            @include anim-f(opacity);
        }

        &--placeholder {
            height: 0 !important;
        }

        &--main {
            &-logo,
            &-burger {
                @include anim-f(filter);
                filter: grayscale(1) brightness(0) invert(1);
            }
        }
    }
}