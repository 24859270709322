$screen-min: 320px;

// z-indexes
$z-header: 10;
$z-popup: 20;
$z-select: 30;
$z-tooltip: 8;

// Colors
$color-light: #ffffff;
$color-dark: #161724;
$color-green: #22a352;
$color-green-hover: #27ae60;
$color-purple: #6d2664;
$color-purple-hover: #99388D;
$color-purple-light: #9B51E0;
$color-red: #ED4242;
$color-blue: #0065B5;
