/**
В данный файл можно писать стили для различных кнопок.
*/

button,
input[type="submit"] {
    appearance: none;
    padding: 0;
    border: none;
    display: block;
    background-color: transparent;
    border-radius: 0;
    outline: none;
    box-shadow: none;
}

.btn {
    cursor: pointer;
    display: block;
    width: 100%;
    @include anim-f(all);
    // background-color: $color-orange;
    padding: 15px 25px;
    min-width: 160px;
    font-family: $font1;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: $color-light;
    border: 1px solid $color-green;
    border-radius: 6px;
    background-color: $color-green;

    &:hover {
        background-color: $color-green-hover;
        border-color: $color-green-hover;
    }

    &.inline {
        display: inline-block;
        width: auto;
    }

    &:disabled,
    &.disabled {
        // pointer-events: none;
        background-color: rgba($color-green, 0.4);
        border-color: rgba($color-green, 0);
    }

    &.light {
        color: $color-green;
        background-color: transparent;
        border-color: $color-green;

        svg {
            fill: $color-green;
        }

        &:hover {
            color: $color-light;
            background-color: $color-green-hover;
            border-color: $color-green-hover;

            svg {
                fill: $color-light;
            }
        }

        &.alt {
            color: $color-blue;
            border-color: $color-blue;

            svg {
                fill: $color-blue;
            }

            &:hover {
                color: $color-light;
                background-color: $color-blue;

                svg {
                    fill: $color-light;
                }
            }
        }

        &.dark {
            color: $color-dark;
            border-color: rgba($color-dark, 0.4);

            svg {
                fill: $color-dark;
            }

            &:hover {
                color: $color-green;
                background-color: $color-light;
                border-color: $color-green;

                svg {
                    fill: $color-green;
                }
            }
        }
    }

    svg {
        font-size: 13px;
        @include svgcolor($color-light);

        &.applepay {
            font-size: 16px;
            margin-top: -0.15em;
        }

        &:first-child {
            margin-right: 0.2em;
        }

        &:last-child {
            margin-left: 0.2em;
        }
    }

    &.reversed {
        background-color: $color-light;
        color: $color-green;
        border-color: transparent;

        &:hover {
            background-color: rgba($color-light, 0.9);
        }
    }

    &.clear {
        padding: 0;
        border: none;
        background: transparent;
        color: $color-green;

        &:hover {
            text-decoration: underline;
            color: $color-dark;
        }
    }
}
