.page_lk{
    &--promo {
        position: relative;
        z-index: 1;
        overflow: hidden;
        padding: 40px 16px 84px;

        @include bp(md) {
            padding: 50px 50px 60px;
        }

        @include bp(lg) {
            padding: 50px 77px 60px;
        }

        &-bg {
            @include stretch;
            z-index: -1;

            img {
                @include stretch;
                object-position: 100% 100%;
                object-fit: cover;
            }
        }

        &-title {
            display: block;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 110%;
            margin-bottom: 16px;

            @include bp(md) {
                font-size: 30px;
                line-height: 110%;
                max-width: 410px;
            }
        }

        dl {
            display: block;
            margin-bottom: 16px;

            @include bp(md) {
                width: 50%;
            }

            dt {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 150%;
                margin-bottom: 2px;
            }

            dd {
                font-style: normal;
                font-weight: bold;
                font-size: 24px;
                line-height: 110%;

                small {
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 19px;
                }
            }
        }

        &-container {
            @include bp(md) {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                max-width: 410px;
            }
        }

        .btn {
            margin-top: 8px;
        }
    }
}