.input_container {
    width: 100%;
    display: block;
    position: relative;
    margin-bottom: 38px;
    font-size: 0;
    line-height: 0;
    pointer-events: none;
    @include clearfix;

    @include bp(md) {
        margin-bottom: 26px;
    }

    .form_default--alt_gap & {
        margin-bottom: 18px;

        @include bp(md) {
            margin-bottom: 18px;
        }
    }

    &.d-none {
        display: none;
    }

    &:last-child {
        margin-bottom: 0;
    }

    input,
    textarea,
    select {
        pointer-events: all;
    }

    &--label {
        display: block;
        position: absolute;
        left: 14px;
        top: 14px;
        font-size: 16px;
        line-height: 1.1em;
        @include anim-f(transform, color, opacity);
        transform: translate3d(0, 0, 0) scale(1);
        transform-origin: 0 100%;
        pointer-events: none;
        opacity: 0.7;
        color: $color-dark;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: auto;
        max-width: 100%;
        right: 14px;
        overflow: hidden;

        .focused ~ &,
        select ~ & {
            transform: translate3d(0, calc(-100% - 20px), 0) scale(0.75);
            opacity: 1;
            white-space: normal;
            width: calc(133% - 14px);
            max-width: none;
        }

        .no-stars & {
            &:after {
                display: none !important;
            }
        }

        *:required ~ & {
            &:after {
                content: "*";
                display: inline;
                vertical-align: baseline;
                padding-left: 0.1em;
            }
        }
    }

    &--error {
        display: none;
        font-size: 12px;
        line-height: 1.1em;
        color: $color-red !important;
        position: static;
        // top: 100%;
        margin-top: 4px;
        // left: 14px;
        margin-left: 14px;

        input[type="checkbox"] ~ &,
        input[type="radio"] ~ & {
            margin-left: 34px;
        }

        input:valid ~ &,
        textarea:valid ~ & {
            display: none;
        }
        &.validation-visible {
            display: block;
        }
    }

    &--switch {
        position: absolute;
        display: block;
        z-index: 2;
        width: 24px;
        height: 24px;
        @include anim-f(opacity);
        opacity: 0.5;
        pointer-events: all;
        top: 13px;
        right: 13px;

        svg {
            @include centre;
            @include svgcolor($color-dark);

            &:first-child {
                display: block;
            }

            &:last-child {
                display: none;
            }
        }

        &:hover {
            opacity: 1;
        }

        input[type="text"] ~ & {
            svg {
                &:first-child {
                    display: none;
                }

                &:last-child {
                    display: block;
                }
            }
        }
    }

    &--note {
        display: block;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 150%;
        margin-top: 4px;
        opacity: 0.7;
        white-space: nowrap;
    }

    &--expandable {
        position: relative;
        z-index: 5;

        & > input,
        & > textarea,
        & > select {
            z-index: 2;
            position: relative;
        }

        &:focus .input_container--expandable-content,
        & > input:focus ~ .input_container--expandable-content,
        & > textarea ~ .input_container--expandable-content,
        & > select ~ .input_container--expandable-content {
            border-color: $color-green;
            max-height: 500px;
        }

        &-content {
            display: block;
            position: absolute;
            top: 0;
            z-index: 1;
            width: 100%;
            background: #f3f3f4;
            border: 1px solid #f3f3f4;
            box-sizing: border-box;
            border-radius: 7px;
            @include anim-f(border, max-height);
            max-height: 0;
            overflow: hidden;
            padding-top: 50px;
            padding: 40px 13px 0;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 150%;
            color: $color-dark;
            pointer-events: all;

            & > span {
                display: block;
                margin-bottom: 10px;
            }

            & > span.focused {
                background: #bebec0;
            }
        }

        &-insert {
            display: inline;
            width: auto;
            font-weight: 600;
            color: $color-green;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &--unit {
        display: block;
        position: absolute;
        z-index: 2;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        color: rgba($color-dark, 0.6);
        top: 13px;
        right: 14px;
        opacity: 0;
        @include anim-f(opacity);

        .focused ~ &{
            opacity: 1;
        }
    }
}
