.block_default {
    padding: 30px 0;

    @include bp(lg) {
        padding: 80px 0;
    }

    &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
    }

    &--header {
        margin-bottom: 60px;

        h1 {
            @extend %header-medium;
            margin-bottom: 20px;
        }

        p,
        ul,
        ol,
        h3,
        h4,
        h5 {
            max-width: 946px;
        }
    }

    &--foot {
        text-align: center;
        margin-top: 36px;

        .btn {
            @include bp(md) {
                width: 200px;
                margin: 0 auto;
            }
        }
    }
}
