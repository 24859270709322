.page_questionnaire {
    .page_header {
        &--menu-enter {
            @include bp(xl) {
                a,
                button {
                    min-width: 130px;
                    border: 1px solid $color-green;
                    color: $color-dark !important;
                    background-color: transparent;
                    padding: 10px;

                    &:hover {
                        color: $color-light !important;
                        background-color: $color-green-hover;
                    }
                }
            }
        }
    }
}

.layout_questionnaire {
    padding: 0px 0 40px;

    @include bp(md) {
        padding: 30px 0 0;

        .wrapper{
            max-width: 994px;
        }
    }

    &--notice {
        text-align: center;
        font-style: normal;
        font-size: 14px;
        line-height: 150%;
        position: relative;
        z-index: 1;
        padding: 18px 0;
        margin-bottom: 20px;

        @include bp(md) {
            max-width: 558px;
            font-size: 16px;
            line-height: 150%;
            padding: 20px 18px 16px;
            margin: 0 auto 20px;
        }

        &:before {
            @include pseudos;
            position: absolute;
            z-index: -1;
            width: 100vw;
            height: 100%;
            background-color: $color-light;
            left: 50%;
            margin-left: -50vw;
            box-shadow: 4px 4px 30px rgba($color-purple, 0.06);
            border-radius: 4px;
            top: 0;

            @include bp(md) {
                width: 100%;
                left: 0;
                margin-left: 0;
            }
        }

        span {
            display: block;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 110%;
            color: $color-purple;
            margin-bottom: 6px;

            @include bp(md) {
                display: inline-block;
                vertical-align: baseline;
                margin-right: 10px;
                font-size: 20px;
                line-height: 110%;
                margin-bottom: 0;
            }
        }
    }

    &--progress {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 120%;
        text-align: center;
        /*margin-bottom: 14px;*/

        @include bp(md) {
            margin-bottom: 20px;

            p {
                font-size: 16px;
                line-height: 150%;
            }
        }

        &-bar {
            margin-top: 15px;
            color: $color-purple;
            position: relative;
            width: 100vw;
            height: 24px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            left: 50%;
            margin-left: -50vw;
            background: $color-light;
            border: 1px solid rgba($color-purple, 0.2);
            box-sizing: border-box;
            border-radius: 4px;
            z-index: 1;

            @include bp(md) {
                margin-top: 6px;
                width: 100%;
                left: 0;
                margin-left: 0;
                height: 24px;
            }

            i {
                display: block;
                position: absolute;
                height: 100%;
                border-radius: 4px;
                box-shadow: inset 0 0 0 100px rgba($color-purple-light, 0.3);
                top: 0;
                left: 0;
                border: 2px solid transparent;
                z-index: -1;
                @include anim-f(width);
            }
        }
    }

    &--form {
        padding-top: 18px;
        padding-bottom: 40px;
        border-bottom: 1px solid rgba($color-purple, 0.2);

        @include bp(md) {
            background: $color-light;
            box-shadow: 4px 4px 30px rgba($color-purple, 0.1);
            border-radius: 4px;
            padding: 40px 40px 60px;
            border: none;

            &-smaller{
                margin-left: auto;
                margin-right: auto;
                max-width: 695px;
                padding: 60px 30px 80px;
            }
        }

        & > .block_text{
            margin-top: -18px;
            margin-bottom: 20px;

            @include bp(md){
                margin-top: -6px;
                margin-bottom: 50px;
            }

            & + h2{
                margin-bottom: 24px;
            }

            a.file{
                color: $color-dark;

                span{
                    text-decoration: none;
                }

                &:hover{
                    color: $color-purple-hover;

                    span{
                        text-decoration: underline;
                    }
                }
            }
        }

        .row {
            margin-bottom: -38px;

            @include bp(md) {
                margin-bottom: -26px;
            }

            & > * {
                margin-bottom: 32px;

                @include bp(md) {
                    margin-bottom: 26px;
                }

                .form_default--timer{
                    margin-bottom: 0;

                    @include bp-to(md){
                        margin-bottom: -18px;
                    }
                }

                & > p{
                    @include bp-to(md){
                        margin-top: -8px;
                    }
                }
            }

            & + .row {
                margin-top: 28px;

                @include bp(md) {
                    margin-top: 34px;
                }
            }
        }

        h2 {
            @extend %header-medium3;
            margin-bottom: 28px;

            @include bp(md) {
                margin-bottom: 26px;
            }
        }

        h3 {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 150%;
            margin-top: 28px;
            margin-bottom: 28px;

            @include bp(md) {
                margin: 28px 0 36px;
            }
        }

        .form_default {
            fieldset {
                margin-top: -18px;

                @include bp(md) {
                    margin-top: -6px;
                }
            }
        }

        &-foot {
            margin-top: 18px;

            .row {
                margin-bottom: -20px;

                & > * {
                    margin-bottom: 20px;
                }
            }

            .btn {
                @include bp(md) {
                    padding: 12px 20px;
                }
            }

            .layout_questionnaire--form-alt_spaces {
                margin-top: 20px;
                margin-bottom: -18px;
            }
        }

        &-alt_spaces {
            max-width: 690px;

            .input_container {
                margin-bottom: 18px;

                &:last-child {
                    margin-bottom: 0;
                }

                &.d-none {
                    display: none;
                }
            }
        }

        &-countered {
            counter-reset: headCounter;

            .layout_questionnaire--form-section:not(.d-none) {
                counter-increment: headCounter;

                h3 {
                    &:after {
                        content: " №" counter(headCounter);
                        display: inline;
                    }
                }
            }
        }

        &-section {
            margin-top: -8px;
            margin-bottom: 30px;

            @include bp(md) {
                margin-top: -6px;
            }

            h3 {
                margin-top: 0 !important;
            }

            &-head {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: baseline;

                button {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 150%;
                    display: inline-block;
                    color: $color-green;
                    @include anim-f(color);

                    &:hover {
                        color: $color-purple;
                    }
                }
            }
        }

        &-text {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 150%;
            margin-top: -22px;

            @include bp(md) {
                margin-top: -26px;
            }

            .form_default--img_preview {
                float: left;
                margin-right: 20px;
                margin-bottom: 20px;

                @include bp-to(sm) {
                    &:nth-child(2n) {
                        margin-right: 0;
                    }
                }

                @include last(2) {
                    margin-bottom: 0;
                }
            }

            & > p,
            & > ul,
            & > ol {
                margin: 14px 0;

                &:first-child {
                    margin-top: 0;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }

            ol {
                counter-reset: cnt;

                @include bp(lg) {
                    padding-left: 10px;
                    padding-right: 10px;
                }

                li {
                    counter-increment: cnt;
                    margin-bottom: 30px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    & > span {
                        position: relative;
                        padding-left: 20px;
                        display: block;
                        margin-bottom: 20px;

                        &:before {
                            content: counter(cnt) ".";
                            position: absolute;
                            display: inline-block;
                            top: 0;
                            left: 0;
                            font-style: normal;
                            font-weight: bold;
                            color: $color-purple;
                        }
                    }
                }
            }

            input[type="file"] {
                & ~ .input_container--files {
                    display: none !important;
                }

                & ~ .input_container--label {
                    padding-left: 0;
                    color: $color-green;
                    font-size: 16px;
                    line-height: 150%;
                    max-width: none !important;

                    @include bp(md) {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: baseline;

                        small {
                            margin-top: 0;
                            margin-left: 20px;
                        }
                    }

                    svg {
                        position: static;
                        margin-right: 5px;
                        @include svgcolor($color-green);

                        @include bp(md) {
                            margin-top: -3px;
                        }
                    }

                    small {
                        color: $color-dark;
                        white-space: normal;
                        display: block;
                        margin-top: 8px;
                        font-size: 10px;
                        line-height: 1.2em;
                    }
                }

                &:focus ~ .input_container--label,
                & ~ .input_container--label:hover {
                    color: $color-purple;

                    svg {
                        fill: $color-purple;
                    }
                }
            }
        }
    }

    &--checking {
        text-align: center;
        color: $color-dark;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba($color-light, 0.5);
        backdrop-filter: blur(4px);
        opacity: 0;
        z-index: -999;
        visibility: hidden;
        @include anim-f(opacity);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 30px 15px;

        &.show {
            visibility: visible;
            z-index: #{$z-popup + 1};
            opacity: 1;
        }

        &-progress {
            width: 108px;
            height: 108px;
            margin-bottom: 26px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            position: relative;
            box-shadow: inset 0 0 0 8px rgba($color-purple, 0.1);
            border-radius: 50%;
            font-style: normal;
            font-weight: 800;
            font-size: 24px;
            line-height: 110%;
            color: $color-purple;

            @include bp-to(md) {
                transform-origin: 50% 100%;
                transform: scale(0.8);
                margin-bottom: 15px;
            }

            small {
                font-size: 0.6em;
                margin-top: 6px;
            }

            svg {
                @include stretch;
                fill: none;
                stroke-width: 8px;
                stroke: $color-purple;
                stroke-dasharray: 320px;
                stroke-dashoffset: -320px;
                transform-origin: 50% 50%;
                transform: rotate(-90deg);
                @include anim-f(all);
            }
        }

        & > span {
            display: block;
            margin-bottom: 14px;
            font-weight: bold;
            font-size: 24px;
            line-height: 110%;
        }
    }
}

.popup_card {
    text-align: center;
    padding: 60px 16px;

    @include bp(md) {
        width: 502px;
        text-align: left;
        padding: 80px 30px 60px;
    }

    .btn {
        padding-top: 12px;
        padding-bottom: 12px;
    }
}
