.page_lk {
    &--edit {
        &_section {
            padding: 40px 16px 60px;

            @include bp(md) {
                padding: 50px 40px;
            }

            & > .accordion {
                margin: 0 !important;

                .accordion-header {
                    & > span {
                        font-weight: bold;
                        font-size: 24px;
                        line-height: 110%;
                    }
                }

                .accordion-container {
                    padding: 38px 0 0;

                    @include bp(md) {
                        padding: 52px 0 10px;
                    }
                }
            }

            .form_default {
                fieldset {
                    margin-top: -18px;

                    @include bp(md) {
                        margin-top: -9px;
                    }

                    .input_container {
                        margin-top: 10px;

                        @include bp(md) {
                            margin-top: 6px;
                        }
                    }
                }

                &--field_group {
                    margin-bottom: 30px;

                    & + .form_default--field_group {
                        @include bp(md) {
                            margin-top: -4px;
                        }
                    }

                    .row {
                        margin-bottom: -14px;

                        & > * {
                            margin-bottom: 14px;
                        }
                    }

                    & + * .form_default--add {
                        @include bp(md) {
                            padding-left: 44px;
                        }
                    }
                }
            }

            h3 {
                font-weight: 600;
                font-size: 16px;
                line-height: 150%;
                margin: 32px 0 38px;

                @include bp(md) {
                    margin: 30px 0 36px;
                }
            }

            &-foot {
                margin-top: 40px;

                .btn{
                    min-width:  0;
                    padding-left: 10px;
                    padding-right: 10px;
                }
            }

            .row:hover > .page_lk--edit_section-remove {
                @include bp-to(md) {
                    display: block;
                }

                @include bp(md) {
                    opacity: 1;
                    visibility: visible;
                    transition-delay: 0s;
                }
            }

            &-remove {
                display: none;

                @include bp(md) {
                    display: block;
                    visibility: hidden;
                    opacity: 0;
                    transition: visibility 0.001s linear 0.2s, opacity 0.2s ease;
                }

                button {
                    display: inline-block;
                    width: auto;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 150%;
                    @include anim-f(color);

                    &:hover {
                        color: $color-green;
                    }
                }
            }
        }

        &_delete {
            .page_lk--layout-section-header {
                margin-bottom: 20px;

                @include bp(md) {
                    margin-bottom: 20px;
                }
            }
        }

        &_popup {
            padding-top: 60px;
            padding-bottom: 60px;
            max-width: 503px;

            @include bp(md){
                padding: 50px 37px 60px;
            }

            &-head {
                position: relative;

                @include bp(md){
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: flex-start;
                    padding-right: 30px;

                    & > small{
                        position: static;
                        margin-top: 6px;
                        margin-bottom: 0;
                        order: 2;
                    }
                }

                & > small {
                    display: block;
                    position: absolute;
                    bottom: 100%;
                    margin-bottom: 26px;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 9px;
                    line-height: 150%;
                    text-transform: uppercase;
                    color: $color-green;
                }
            }

            p{
                @include bp(md){
                    max-width: 400px;
                }

                a{
                    font-weight: 700;
                    text-decoration: underline;

                    &:hover{
                        color: $color-green;
                    }
                }
            }

            .btn.inline{
                min-width: 200px;

                @include bp-to(md){
                    display: block;
                    width: 100%;
                }

                @include bp(md){
                    padding-left: 35px;
                    padding-right: 35px;
                }
            }

            .btn:not(.inline){
                min-width: 0;
            }

            .btn{
                padding-left: 10px;
                padding-right: 10px;
            }

            .form_default{
                margin-top: 38px !important;

                @include bp(md){
                    width: 333px;
                }
            }

            &-foot{
                margin-top: 30px;
            }
        }
    }
}
