.accordion {
    &-root {
        margin: 0 -10px;
        padding: 20px 10px;
        border-bottom: 1px solid rgba($color-dark, 0.2);

        @include bp(md){
            margin: 0;
            padding: 25px 0;
        }
    }

    &-header {
        cursor: pointer;
        display: block;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 120%;
        padding-left: 32px;
        position: relative;
        @include anim-f(color);

        @include bp(md) {
            font-weight: 600;
            font-size: 20px;
            line-height: 110%;
            padding-left: 44px;
        }

        &:hover{
            color: $color-green;
        }

        &:before {
            @include pseudos;
            @extend %icon-base;
            @extend %icon-plus-green;
            position: absolute;
            top: 3px;
            left: 0;
            transform-origin: 50% 50%;
            @include anim-f(transform);
            transform: translateZ(0) rotate(0);

            @include bp-to(md) {
                width: 15px;
                height: 15px;
            }

            @include bp(md){
                top: 0;
            }

            .expanded &{
                transform: translateZ(0) rotate(-45deg);
            }
        }
    }

    &-content {
        overflow: hidden;
        transition: max-height 0.2s ease-in;
        @include anim-m(max-height);

        .accordion-root:not(.expanded) > & {
            max-height: 0 !important;
        }
    }

    &-container {
        padding-top: 14px;

        @include bp(md){
            padding-top: 20px;
        }
    }
}
