.page_lk {
    main {
        @include bp(md) {
            background: linear-gradient(180.05deg, #ffffff -14.31%, #f9f6f9 99.96%);
        }
    }

    .page_footer {
        @include bp(lg) {
            margin-top: 0;
            padding-top: 70px;
            border-top: 1px solid rgba($color-purple, 0.15);
        }
    }
}

@import './lk/layout';
@import './lk/popup';
@import './lk/calculate';
@import './lk/loan';
@import './lk/graph';
@import './lk/history';
@import './lk/promo';
@import './lk/question';
@import './lk/report';
@import './lk/edit';
@import './lk/change_password';
