.block_documents {
    .block_text {
    }

    &--list {
        margin-bottom: 20px;
        margin-top: 30px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;

        @include bp(lg) {
            margin-top: 40px;
            max-width: 950px;
        }

        &:last-child{
            margin-bottom: -30px !important;

            @include bp(lg){
                margin-bottom: -40px !important;
            }
        }
    }

    &--item {
        width: 100%;
        margin-bottom: 30px;
        position: relative;
        padding-left: 42px;
        min-height: 38px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 150%;

        @include bp(md){
            width: calc(50% - 12px);
            margin-right: 24px;

            &:nth-child(2n){
                margin-right: 0;
            }
        }

        @include bp(lg){
            margin-bottom: 40px;
            padding-left: 50px;
            min-height: 50px;
        }

        &:hover{
            text-decoration: underline;
        }

        small {
            font-family: $font2;
            color: $color-purple;
            z-index: 1;
            text-transform: uppercase;
            font-size: 8px;
            letter-spacing: 0.04em;
            font-weight: 600;
            line-height: 38px;
            height: 38px;
            text-align: center;
            width: 28px;
            top: 0;
            left: 0;
            position: absolute;
            background-color: rgba($color-dark, 0.06);
            border-radius: 2px;

            @include bp(lg){
                width: 38px;
                height: 50px;
                line-height: 50px;
                font-size: 11px;
                letter-spacing: 0.04em;
            }

            &:before {
                @include pseudos;
                position: absolute;
                z-index: -1;
                top: 0;
                right: 0;
                border-radius: 0 0 0 2px;
                background-image: linear-gradient(
                    45deg,
                    rgba($color-dark, 0.1) 50%,
                    $color-light 50%,
                    $color-light 100%
                );
                width: 8px;
                height: 8px;
            }
        }
    }
}
