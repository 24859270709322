.page_lk{
    &--graph {
        .page_lk--layout-section-header {
            margin-bottom: 14px;

            @include bp(md) {
                margin-bottom: 20px;
            }
        }

        &-subheader {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 150%;
            color: rgba($color-dark, 0.6);
            margin-bottom: 20px;

            @include bp(md) {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: baseline;
                margin-bottom: 30px;
            }

            & > * {
                display: block;
                margin-bottom: 4px;

                @include bp(md) {
                    margin-bottom: 0;
                    margin-right: 47px;

                    &:last-child {
                        margin-right: 0;
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .block_text {
            &--table {
                @include bp-to(md) {
                    width: auto;
                    margin-left: -16px;
                    margin-right: -16px;

                    &-container {
                        padding-left: 16px;
                        padding-right: 16px;
                    }
                }

                @include bp(md, lg) {
                    width: auto;
                    margin-left: -40px;
                    margin-right: -40px;

                    &-container {
                        padding-left: 40px;
                        padding-right: 40px;
                    }
                }
            }
        }
    }
}