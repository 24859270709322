.block_faq {
    .block_default--header {
        margin-bottom: 20px;

        @include bp(md) {
            text-align: center;
            margin-bottom: 25px;
        }
    }

    .block_default--content {
        margin-left: auto;
        margin-right: auto;
        max-width: 752px;
    }
}

.block_ask_question {
    padding: 60px 0 80px !important;
    background-color: $color-purple;
    position: relative;
    overflow: hidden;
    z-index: 1;

    @include bp(xl) {
        padding: 20px 0 80px !important;
        background-color: transparent;

        .wrapper {
            padding: 70px 267px 80px;

            &:before {
                @include pseudos;
                position: absolute;
                top: 0;
                right: 170px;
                width: 100vw;
                height: 100%;
                border-radius: 8px;
                background: $color-purple;
                box-shadow: 8px 8px 30px rgba($color-dark, 0.06);
                z-index: -2;
            }

            &:after {
                @include pseudos;
                position: absolute;
                z-index: -3;
                background: $color-green;
                border-radius: 0px 0px 0px 8px;
                width: 100vw;
                height: auto;
                bottom: -80px;
                top: 80px;
                left: 194px;
            }
        }
    }

    &:before {
        @include pseudos;
        z-index: -1;
        width: 62.23px;
        height: 62.23px;
        background: linear-gradient(225deg, rgba($color-light, 0.1) 15.85%, rgba($color-light, 0) 95.75%);
        border-radius: 10px;
        transform: rotate(-45deg);
        transform-origin: 50% 50%;
        top: -10px;
        left: 65%;
        position: absolute;

        @include bp(xl) {
            width: 216.37px;
            height: 216.37px;
            background: linear-gradient(225deg, rgba($color-light, 0.05) 15.85%, rgba($color-light, 0) 95.75%);
            border-radius: 40px;
            transform: rotate(-45deg);
            left: 20%;
            top: -40px;
        }
    }

    &:after {
        @include pseudos;
        z-index: -1;
        width: 148.49px;
        height: 148.49px;
        background: linear-gradient(225deg, rgba($color-light, 0.1) 15.85%, rgba(255, 255, 255, 0) 95.75%);
        border-radius: 30px;
        transform: rotate(-45deg);
        top: 10px;
        left: 65%;
        margin-left: 60px;
        position: absolute;

        @include bp(xl) {
            width: 411.5px;
            height: 411.5px;
            background: linear-gradient(225deg, rgba($color-light, 0.05) 8.25%, rgba($color-light, 0) 95.46%);
            border-radius: 80px;
            transform: rotate(135deg);
            top: 0;
            left: 20%;
            margin-left: -280px;
        }
    }

    .block_default--header {
        color: white;
        margin-bottom: 40px;
    }

    .form_default {
        @include bp(xl) {
            width: 606px;
        }

        .row {
            margin-bottom: -40px;

            & > * {
                margin-bottom: 40px;
            }
        }

        .input_container {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }

            &:last-of-type:not(:last-child) {
                margin-bottom: 30px;
            }
        }

        [type="submit"] {
            @include bp(md) {
                width: 194px;
            }
        }
    }

    .block_default--content > .block_text{
        padding: 30px 14px;
        margin: 0 -14px 40px;
        background-color: $color-light;
        font-size: 14px;
        line-height: 150%;
        border-radius: 7px;

        @include bp(md){
            padding: 30px 24px 40px;
            margin: 0 0 40px;
        }

        p, ul, ol{
            margin: 20px 0;

            &:first-child{
                margin-top: 0;
            }

            &:last-child{
                margin-bottom: 0;
            }
        }

        p + ul{
            margin-top: -10px;
        }

        ul{
            padding-left: 10px;

            li{
                padding-left: 15px;

                &:before{
                    background-color: $color-purple;
                    width: 5px;
                    height: 5px;
                    top: 8px;
                }
            }
        }
    }
}
