.block_how_to {
    padding-top: 30px;
    padding-bottom: 80px;

    .block_default--content {
        .block_text {
            counter-reset: cnt;

            .row {
                margin-bottom: -20px;

                @include bp(lg) {
                    margin-bottom: -80px;
                }

                & > * {
                    margin-bottom: 20px;
                    opacity: 0;
                    @include anim-f(opacity);
                    transition-delay: 0.2s;

                    @include bp-to(lg) {
                        &:nth-child(2n - 1) {
                            &:not(:first-child) {
                                margin-top: 40px;
                            }
                        }
                    }

                    @include bp(lg) {
                        margin-bottom: 80px;

                        &.anim-order1,
                        &.anim-order2 {
                            transition-delay: 0.3s;
                        }
                    }

                    &.anim-inViewport {
                        opacity: 1;
                    }
                }
            }

            h3 {
                counter-increment: cnt;
                font-size: 24px !important;
                line-height: 1.1em !important;

                @include bp(lg) {
                    position: relative;
                    padding-left: 30px;
                }

                &:before {
                    content: counter(cnt) ".";
                    display: inline-block;
                    vertical-align: baseline;
                    margin-right: 0.2em;

                    @include bp(lg) {
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }
            }

            p {
                @include bp(lg) {
                    margin: 14px 0;
                    font-size: 14px;
                    line-height: 150%;
                }

                img {
                    display: block;
                    position: relative;
                    width: 100%;

                    @include bp-to(md) {
                        width: 100vw;
                        max-width: 100vw;
                        left: 50%;
                        margin-left: -50vw;
                    }
                }
            }
        }
    }
}

.block_get_loan {
    background-color: $color-purple;
    position: relative;
    text-align: center;
    color: $color-light;
    padding: 100px 0;
    overflow: hidden;
    z-index: 1;

    .wrapper {
        &:before {
            @include pseudos;
            background: linear-gradient(135deg, $color-light 15.85%, rgba($color-light, 0) 95.75%);
            opacity: 0.1;
            border-radius: 10px;
            transform: rotate(-45deg);
            width: 62.23px;
            height: 62.23px;
            position: absolute;
            right: 30px;
            top: -100px;
            z-index: -1;

            @include bp(lg) {
                width: 411.5px;
                height: 411.5px;
                top: -80px;
                right: 115px;
                border-radius: 80px;
                background: linear-gradient(135deg, $color-light 8.25%, rgba($color-light, 0) 95.46%);
            }
        }

        &:after {
            @include pseudos;
            width: 148.49px;
            height: 148.49px;
            background: linear-gradient(225deg, #ffffff 15.85%, rgba(255, 255, 255, 0) 95.75%);
            opacity: 0.1;
            border-radius: 30px;
            transform: rotate(-45deg);
            position: absolute;
            right: -60px;
            top: -30px;
            z-index: -1;

            @include bp(lg) {
                border-radius: 40px;
                right: 0;
                top: -115px;
                width: 216.37px;
                height: 216.37px;
            }
        }
    }

    h2 {
        @extend %header-medium;
        max-width: 752px;
        margin: 0 auto 34px;
    }
}
