.page_footer {
    margin-top: 60px;

    @include bp(lg) {
        margin-top: 160px;
    }

    &--row {
        margin-bottom: 40px;

        @include bp(md) {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            flex-wrap: wrap;
        }

        @include bp(md, lg) {
            & > * {
                width: 45%;

                &:last-child {
                    width: auto;
                }

                &:first-child {
                    width: 100%;
                }

                &:nth-last-child(2) {
                    margin-bottom: 0;
                }
            }
        }

        @include bp(lg) {
            margin-bottom: 50px;
        }

        &:last-child {
            font-family: $font1;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            color: rgba($color-dark, 0.5);

            @include bp(lg) {
                margin-bottom: 20px;
            }

            .page_footer--col {
                margin-bottom: 16px;
            }

            a {
                text-decoration: underline;

                &:hover {
                    color: $color-dark;
                }
            }
        }
    }

    &--col {
        margin-bottom: 30px;

        @include bp(lg) {
            margin-bottom: 0 !important;
            width: 42.3%;
            max-width: 360px;
            margin-left: 0;
            margin-right: auto;

            &:first-child {
                width: 34.2%;
                margin-right: 0;
            }

            &:last-child {
                width: 23.1%;
                margin-left: 0;
                margin-right: 0;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &--logo {
        font-size: 0;
        line-height: 0;
        text-align: center;

        @include bp(lg) {
            text-align: left;
        }

        a {
            display: inline-block;
            transform: translateX(-30px);
        }
    }

    nav {
        font-family: $font1;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        columns: 2;

        a {
            color: $color-dark;
            display: block;
            margin-bottom: 20px;
            break-inside: avoid;

            &:last-child {
                margin-bottom: 0;
            }

            &:hover {
                color: $color-green;
            }
        }
    }

    &--contacts {
        font-family: $font1;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 150%;
        color: rgba($color-dark, 0.6);

        p + p {
            margin-top: 10px;
        }

        p {
            span {
                display: block;
                margin-top: 4px;
            }
        }

        a {
            display: inline-block;
            font-weight: bold;
            font-size: 24px;
            line-height: 110%;
            color: $color-purple;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &--creator {
        margin-top: 14px;
        display: inline-block;
        @include anim-f(filter);
        filter: grayscale(0) brightness(1);

        @include bp(md) {
            margin-top: 0;
        }

        &:hover {
            filter: grayscale(1) brightness(0);
        }
    }

    &--cookie, &--mobile {
        position: fixed;
        width: 100%;
        padding: 20px 0;
        background-color: rgba($color-green, 0.9);
        color: white;
        bottom: 0;
        left: 0;
        z-index: $z-header - 1;
        font-size: 14px;
        line-height: 1.5em;

        @include bp(md) {
            padding: 30px 0;
            font-size: 16px;
            line-height: 1.5em;
        }

        .row{
            margin-bottom: -20px;

            & > *{
                margin-bottom: 20px;
            }
        }

        p{
            a{
                font-weight: 700;
                text-decoration: underline;

                &:hover{
                    text-decoration: none;
                }
            }
        }
    }

    &--mobile {
        background-color: rgba($color-blue, 0.9);
    }
}
.grecaptcha-badge {visibility: hidden;}
