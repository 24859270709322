.page_lk{
    &--history {
        &-section {
            padding: 0;
            margin: 0;
            width: 100%;
            padding-bottom: 20px;
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }

            @include bp(md) {
                padding-bottom: 30px;
                margin-bottom: 30px;
                padding-top: 0;

                .accordion-header {
                    & > small {
                        & > span {
                            &:first-child {
                                display: inline;
                            }

                            &:last-child {
                                display: none;
                            }
                        }
                    }
                }
            }

            &.expanded {
                .accordion-header {
                    & > small {
                        &:after {
                            transform: translateZ(0) rotate(-225deg);
                            top: 3px;

                            @include bp(md) {
                                top: 9px;
                            }
                        }

                        @include bp(md) {
                            & > span {
                                &:first-child {
                                    display: none;
                                }

                                &:last-child {
                                    display: inline;
                                }
                            }
                        }
                    }
                }
            }

            .accordion-header {
                padding-left: 0;
                font-style: normal;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;

                .title {
                    font-size: 20px;
                    line-height: 27px;
                    display: block;
                    font-weight: 700;
                    color: $color-green;
                }

                .details {
                    width: 100%;
                    margin-top: 14px;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 150%;
                    color: $color-dark;
                    margin-bottom: -6px;

                    @include bp(md) {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                    }

                    & > * {
                        display: block;
                        margin-bottom: 6px;

                        @include bp(md) {
                            margin-right: 36px;

                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }

                &:before {
                    display: none;
                }

                & > small {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 150%;
                    color: $color-green;
                    position: relative;
                    width: 12px;
                    height: 12px;
                    padding-right: 12px;

                    @include bp(md) {
                        width: auto;
                        padding-right: 20px;
                        height: auto;
                    }

                    & > span {
                        display: none;
                    }

                    &:after {
                        @include pseudos;
                        @include arrow(8px, 2px, $color-green, down);
                        position: absolute;
                        right: 2px;
                        top: 0;
                        left: auto;
                        @include anim-f(transform, top);

                        @include bp(md) {
                            @include arrow(6px, 1px, $color-green, down);
                            top: 5px;
                        }
                    }
                }
            }

            .accordion-container {
                padding: 30px 0;

                @include bp(md) {
                    padding: 40px 0 0;
                }

                @include bp(xl) {
                    padding: 40px 40px 0;
                }
            }

            .block_text {
                a.file {
                    font-size: 14px;
                    line-height: 150%;
                }
            }
        }
    }
}