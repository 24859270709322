.block_about{
    .block_default--header{
        h3{
            margin: 40px 0 14px;
        }

        ul{
            padding-left: 10px;

            li{
                margin-bottom: 6px;

                &:before{
                    background-color: $color-purple;
                    width: 5px;
                    height: 5px;
                }

                &:last-child{
                    margin-bottom: 0;
                }
            }

            &.clear{
                li{
                    margin-bottom: 14px;

                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }
        }

        a.file{
            color: $color-dark;

            &:hover{
                color: $color-purple-hover;
            }
        }
    }
}