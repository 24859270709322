.page_lk{
    &--popup {
        padding: 80px 16px 60px;

        @include bp(md) {
            width: 695px;
            padding: 80px 30px 60px;
        }

        &-head {
            position: relative;
            display: block;
            margin-bottom: 14px;

            &:not(:first-child) {
                margin: 50px 0 40px;

                @include bp(md) {
                    margin-bottom: 30px;
                }
            }

            & > small {
                display: block;
                font-style: normal;
                font-weight: bold;
                font-size: 9px;
                line-height: 150%;
                text-transform: uppercase;
                color: $color-green;
                position: absolute;
                left: 0;
                bottom: 100%;
                margin-bottom: 26px;

                @include bp(md) {
                    margin-bottom: 16px;
                }
            }

            & > p {
                @include bp(md) {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                }

                .input_container {
                    width: 100%;
                    margin-bottom: 0;
                    margin-top: 20px;

                    @include bp(md) {
                        width: 283px;
                        margin-top: 0;
                    }
                }
            }

            .popup-title {
                margin-bottom: 0;
            }

            .cards {
                display: block;
                font-size: 0;
                line-height: 0;
                margin: 18px 0 20px;

                @include bp(md) {
                    margin: 0;
                }

                & > img {
                    display: inline-block;
                    vertical-align: top;
                    margin-right: 30px;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }

        .form_default {
            margin-top: 40px;

            @include bp(md) {
                margin-top: 40px;
            }

            &:first-child {
                margin-top: 0;
            }

            .row > * {
                & > .input_container {
                    margin-bottom: 30px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            &--timer {
                margin-bottom: 0;
            }

            &--field_group {
                & + .page_lk--popup-foot {
                    margin-top: -52px;

                    @include bp(md) {
                        margin-top: -58px;
                    }
                }
            }
        }

        .block_text {
            a.file {
                color: $color-dark;
                text-decoration: underline;

                &:hover {
                    color: $color-purple;
                }
            }
        }

        &-foot {
            @include bp(md) {
                margin-left: 44px;
            }
        }

        &-map {
            width: auto;
            height: 350px;
            margin: 0 -16px;

            @include bp(md) {
                margin: 0;
                width: 100%;
                height: 500px;
            }
        }
    }
}