.nouislider {
    &--title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: baseline;
        margin-bottom: 11px;
    }

    &--name {
        font-family: $font1;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 22px;
        color: $color-dark;
        margin-right: 15px;
    }

    &--value {
        font-family: $font1;
        font-style: normal;
        font-weight: 800;
        font-size: 24px;
        line-height: 33px;
        text-align: right;
        color: #161724;
        white-space: nowrap;

        small {
            font-size: 0.7em;
            display: inline-block;
            vertical-align: baseline;
            margin-left: 0.2em;
        }
    }

    &--points {
        position: relative;
        width: 100%;
        margin-top: 3px;
        height: 16px;

        span {
            display: block;
            font-family: $font1;
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;
            color: $color-dark;
            opacity: 0.3;
            position: absolute;
            top: 0;
            transform: translateX(-50%);

            &:first-child {
                transform: none;
                left: 0 !important;
            }

            &:last-child {
                transform: none;
                left: auto !important;
                right: 0;
            }
        }
    }

    .noUi {
        &-target {
            background: transparent;
            border-radius: 0;
            border: none;
            box-shadow: none;
            height: 18px;
            position: relative;
        }

        &-base {
            width: 100%;
            height: 4px;
            background: rgba($color-dark, 0.2);
            border-radius: 2px;
            position: absolute;
            top: 7px;
            z-index: initial;
        }

        &-connect {
            background-color: $color-green;
            border-radius: 2px;
            z-index: initial;
        }

        &-handle {
            width: 18px;
            height: 18px;
            border-radius: 50%;
            background-color: $color-light;
            border: 3px solid $color-green;
            box-shadow: none;
            transform-origin: 50% 50%;
            @include anim-f(transform);
            transform: translateZ(0) scale(1);
            cursor: pointer;
            outline: none;

            &:hover{
                transform: translateZ(0) scale(1.33);
            }

            &:before,
            &:after {
                display: none;
            }
        }

        &-horizontal .noUi-handle{
            right: -9px;
            top: -7px;
        }
    }
}
