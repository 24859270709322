.page_lk{
    &--loan {
        h3 {
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 1em;
            width: 100%;

            &:before {
                @include pseudos;
                width: 100%;
                height: 1px;
                background-color: rgba($color-purple, 0.15);
                margin: 20px 0;

                @include bp(md) {
                    margin-top: 30px;
                }
            }
        }

        &-row {
            display: table;
            width: 100%;

            @include bp(md) {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;
            }

            p {
                display: table-row;

                @include bp(md) {
                    display: block;
                    margin-right: 60px;

                    &:last-child {
                        margin-right: 0;
                    }
                }

                &:last-child {
                    & > * {
                        padding-bottom: 0;
                    }
                }

                & > * {
                    display: table-cell;
                    padding-bottom: 16px;

                    @include bp(md) {
                        padding: 0 !important;
                        display: block;
                    }

                    &:first-child {
                        padding-right: 10px;
                    }

                    &:last-child {
                        padding-left: 10px;
                    }
                }

                & > span {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 150%;
                    color: rgba($color-dark, 0.6);

                    @include bp(md) {
                        display: block;
                        margin-bottom: 11px;
                    }
                }

                & > strong {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 20px;
                    line-height: 110%;

                    small {
                        font-size: 12px;
                        line-height: 16px;
                    }
                }
            }
        }

        &-row_alt {
            position: relative;
            display: block;
            width: 100%;

            @include bp(md) {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                flex-wrap: wrap;
                margin-bottom: -30px;
            }

            @include bp(lg) {
                flex-wrap: nowrap;
            }

            p {
                font-size: 14px;
                line-height: 150%;
                margin-bottom: 10px;

                @include bp(md) {
                    margin-bottom: 30px;
                    margin-right: 30px;

                    & > * {
                        display: block;
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }

                @include bp(lg) {
                    font-size: 12px;
                    line-height: 150%;

                    & > strong {
                        font-size: 14px;
                        line-height: 150%;
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                    margin-top: 30px;
                    border-top: 1px solid rgba($color-purple, 0.15);
                    border-bottom: 1px solid rgba($color-purple, 0.15);
                    padding: 20px 0;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;

                    @include bp(md) {
                        margin-top: 0;
                        padding: 0;
                        border: none;
                        display: block;
                        margin-bottom: 30px;
                        margin-left: auto;

                        button {
                            margin-top: -2px;
                        }
                    }

                    @include bp(md, lg) {
                        width: 100%;
                        margin-left: 0;
                    }
                }

                & > strong {
                    display: inline-block;
                    margin-left: 5px;

                    @include bp(md) {
                        display: block;
                        margin-left: 0;
                        margin-top: 9px;
                    }
                }
            }
        }

        &_details {
            &-table {
                display: block;

                @include bp(md) {
                    display: table;
                    width: 100%;
                }

                p {
                    margin-bottom: 20px;
                    font-size: 14px;
                    line-height: 150%;

                    @include bp(md) {
                        display: table-row;

                        &:last-child {
                            & > * {
                                padding-bottom: 0;
                            }
                        }

                        & > * {
                            display: table-cell;
                            padding-bottom: 16px;

                            &:last-child {
                                padding-left: 80px;
                            }
                        }
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }

                    & > span {
                        margin-bottom: 2px;
                        color: rgba($color-dark, 0.6);
                    }

                    & > * {
                        display: block;
                    }

                    & > strong {
                        font-weight: 600;

                        a {
                            text-decoration: underline;

                            &:hover {
                                color: $color-green;
                            }
                        }
                    }
                }

                .spacer {
                    display: block;
                    width: 100%;
                    height: 6px;

                    @include bp(md) {
                        height: 10px;
                    }
                }
            }
        }

        &_text {
            .page_lk--layout-section-header {
                margin-bottom: 14px;

                @include bp(md) {
                    margin-bottom: 20px;
                }
            }

            .block_text {
                max-width: 702px;
            }

            .block_text--conditions {
                margin-bottom: 30px;

                @include bp(lg) {
                    padding: 34px 37px 40px;
                    margin: 30px 0;

                    & > div {
                        justify-content: flex-start;

                        p {
                            margin-right: 60px;

                            &:nth-child(4) {
                                margin-left: -30px;
                            }

                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    &--debt_popup {
        padding: 60px 18px;
        text-align: center;

        @include bp(md) {
            width: 563px;
            padding: 50px 30px 60px;
            text-align: left;
        }

        .popup-title {
            font-size: 20px;
            line-height: 120%;
            margin-bottom: 24px;

            @include bp(md) {
                margin-bottom: 26px;
            }

            time {
                color: $color-green;
            }
        }

        &-table {
            text-align: left;
            padding-left: 18px;

            @include bp(md) {
                display: table;
                width: 100%;
                padding-left: 0;
            }

            p {
                margin-bottom: 20px;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 150%;

                @include bp(md) {
                    margin-bottom: 0;
                    display: table-row;
                    font-size: 14px;
                    line-height: 150%;

                    & > * {
                        display: table-cell;
                        padding-bottom: 16px;
                    }

                    &:last-child {
                        & > * {
                            padding-bottom: 0;
                        }
                    }

                    & > strong {
                        font-size: inherit;
                        line-height: inherit;
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                }

                & > * {
                    display: block;
                }

                & > span {
                    margin-bottom: 4px;
                    color: rgba($color-dark, 0.5);
                }

                & > strong {
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 150%;
                }
            }
        }
    }
}