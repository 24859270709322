.page_lk--layout {
    @include bp(md, xl) {
        padding-bottom: 30px;
    }

    @include bp(xl) {
        padding: 50px 0 90px;
    }

    @include bp(xl) {
        .wrapper {
            padding-left: 293px;
        }
    }

    &-section {
        background: $color-light;
        box-shadow: 4px 4px 30px rgba($color-purple, 0.1);
        border-radius: 4px;
        padding: 50px 16px 60px;
        margin: 0 -16px 20px;

        @include bp(md) {
            padding: 50px 40px 60px;
            margin: 0 0 24px;
        }

        &:last-child {
            margin-bottom: 0;
        }

        h2 {
            @extend %header-medium3;
            margin-bottom: 20px;
            width: auto;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &-header {
            margin-bottom: 35px;
            position: relative;
            width: 100%;

            @include bp(md) {
                margin-bottom: 40px;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;
            }

            &-status {
                padding-left: 11px;
                display: block;
                bottom: 100%;
                margin-bottom: 18px;
                @include centre(x);
                font-size: 9px;
                line-height: 150%;
                text-transform: uppercase;

                @include bp(md) {
                    margin-left: 60px;
                    position: relative;
                    transform: none;
                    bottom: auto;
                    margin-bottom: 0;
                    left: auto;
                    margin-top: 10px;
                    order: 2;
                }

                &:before {
                    content: "\2022";
                    display: inline-block;
                    position: absolute;
                    top: 5px;
                    font-size: 14px;
                    left: 0;
                    line-height: 0;
                }
            }
        }

        & > .accordion {
            padding: 0;
            margin: -20px 0 0;
            width: 100%;
            border: none;

            @include bp(md) {
                margin-top: -10px;

                .accordion-header {
                    & > small {
                        & > span {
                            &:first-child {
                                display: inline;
                            }

                            &:last-child {
                                display: none;
                            }
                        }
                    }
                }
            }

            &.expanded {
                .accordion-header {
                    & > small {
                        &:after {
                            transform: translateZ(0) rotate(-225deg);
                            top: 3px;

                            @include bp(md) {
                                top: 9px;
                            }
                        }

                        @include bp(md) {
                            & > span {
                                &:first-child {
                                    display: none;
                                }

                                &:last-child {
                                    display: inline;
                                }
                            }
                        }
                    }
                }
            }

            .accordion-header {
                padding-left: 0;
                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                line-height: 22px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                color: $color-dark;

                &:before {
                    display: none;
                }

                & > small {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 150%;
                    color: $color-green;
                    position: relative;
                    width: 12px;
                    height: 12px;
                    padding-right: 12px;

                    @include bp(md) {
                        width: auto;
                        padding-right: 20px;
                        height: auto;
                    }

                    & > span {
                        display: none;
                    }

                    &:after {
                        @include pseudos;
                        @include arrow(8px, 2px, $color-green, down);
                        position: absolute;
                        right: 2px;
                        top: 0;
                        left: auto;
                        @include anim-f(transform, top);

                        @include bp(md) {
                            @include arrow(6px, 1px, $color-green, down);
                            top: 5px;
                        }
                    }
                }
            }

            .accordion-container {
                padding-top: 20px;
            }
        }
    }
}