$breakpoints: (
    "xs": 0px,
    "sm": 576px,
    "md": 768px,
    "lg": 992px,
    "xl": 1200px
);

$bp_gap: 1px;

@mixin bp-to($bp) {
    @include bp(xs, $bp){
        @content;
    }
}

@mixin bp($args...) {
    @if length($args) == 1 {
        $bp: nth($args, 1);

        @if map-has-key($breakpoints, $bp) {
            $val: map-get($breakpoints, $bp);
            
            @if $val == 0 {
                @content;
            } @else {
                @media screen and (min-width: #{em($val)}) {
                    @content;
                }
            }
        } @else {
            @warn "Unfortunately, no value could be retrieved from `#{$bp}`. "
                + "Please make sure it is defined in `$breakpoints` map.";
        }
    } @else if length($args) == 2 {
        $bpFrom: nth($args, 1);
        $bpTo: nth($args, 2);

        @if map-has-key($breakpoints, $bpFrom) and map-has-key($breakpoints, $bpTo) {
            $queryString: "screen and ";

            @if map-get($breakpoints, $bpFrom) > 0 {
                $queryString: $queryString + "(min-width: #{em(map-get($breakpoints, $bpFrom))}) and ";
            }

            $queryString: $queryString + "(max-width: #{em(map-get($breakpoints, $bpTo) - $bp_gap)})";

            @media #{unquote($queryString)} {
                @content;
            }
        } @else {
            @warn "Unfortunately, no value could be retrieved from `#{$bpFrom}` to `#{$bpTo}`. "
                + "Please make sure it is defined in `$breakpoints` map.";
        }
    } @else {
        @warn "Wrong arguments count for mixin bp";
    }
}

// Медиа-запрос для ретины
@mixin retina {
    @media (-webkit-min-device-pixel-ratio: 2),
        (min-resolution: 192dpi),
        (-webkit-min-device-pixel-ratio: 1.25),
        (min-resolution: 120dpi) {
        @content;
    }
}

@include addClassProperties(
    (
        (d, none, display, none),
        (d, block, display, block),
        (d, inline, display, inline),
        (d, inline-block, display, inline-block),
        (text-align, center),
        (text-align, left),
        (text-align, right)
    )
);

// html,
// body {
//     // Тут могут быть вычисления базового шрифта
//     font-size: $base-font;

//     // Если размер мобилы меньше 375 то пропорционально уменьшаем базовый шрифт.
//     @media (max-width: #{em($maket-small - 1)}){
//         font-size: calc(100vw * #{$base-font/$maket-small});
//     }
// }
