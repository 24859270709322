.page_lk {
    &--report {
        .block_text {
            margin-top: -21px;

            .row {
                margin-top: -10px;
            }
        }

        &-price {
            display: block;
            font-weight: bold;
            font-size: 14px;
            line-height: 120%;

            @include bp-to(md) {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                & > * {
                    &:last-child {
                        display: block;
                        padding-left: 30px;
                    }
                }
            }

            strong {
                font-style: normal;
                font-weight: 800;
                font-size: 50px;
                line-height: 110%;
                color: $color-green;
                margin-bottom: 4px;
                display: block;
                white-space: nowrap;

                @include bp(md) {
                    margin-top: 10px;
                    margin-bottom: 6px;
                }
            }

            small {
                font-size: 0.5em;
                line-height: 1em;
                font-weight: 700;
                font-style: normal;
            }

            s {
                display: inline-block;
                font-style: normal;
                font-weight: bold;
                font-size: 24px;
                line-height: 110%;
                white-space: nowrap;
                text-decoration: none;
                position: relative;

                &:before{
                    @include pseudos;
                    width: 100%;
                    height: 2px;
                    background-color: $color-dark;
                    top: 46%;
                    left: 0;
                    position: absolute;
                }
            }
        }

        &_files {
            &-list {
                max-width: 569px;

                &-item {
                    display: block;
                    position: relative;
                    margin-bottom: 40px;
                    padding-right: 100px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    @include bp(md) {
                        &:hover {
                            color: $color-green;

                            & > span > span {
                                color: $color-green;
                            }
                        }
                    }

                    &:after {
                        @include pseudos;
                        width: 100%;
                        height: 1px;
                        background-color: rgba($color-purple, 0.15);
                        pointer-events: none;
                        left: 0;
                        top: 100%;
                        margin-top: 20px;
                        position: absolute;
                    }

                    & > strong {
                        display: block;
                        color: $color-green;
                        font-weight: bold;
                        font-size: 20px;
                        line-height: 27px;
                        margin-bottom: 4px;
                    }

                    & > small {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 150%;
                    }

                    & > span {
                        display: block;
                        position: absolute;
                        top: 50%;
                        margin-top: -14px;
                        right: 0;

                        @include bp(md) {
                            top: 0;
                            margin-top: 0;
                            padding-left: 52px;
                        }

                        & > i,
                        & > small {
                            display: none;

                            @include bp(md) {
                                display: block;
                            }
                        }

                        & > i {
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 38px;
                            height: 50px;
                            line-height: 50px;
                            font-weight: 700;
                            font-size: 10px;
                            text-transform: uppercase;
                            text-align: center;
                            letter-spacing: 0.02em;
                            color: $color-green;
                            font-style: normal;
                            background-color: rgba($color-dark, 0.06);
                            border-radius: 3px;

                            &:after {
                                @include pseudos;
                                border-style: solid;
                                border-width: 5px;
                                border-color: $color-light $color-light rgba($color-dark, 0.1) rgba($color-dark, 0.1);
                                position: absolute;
                                right: 0;
                                top: 0;
                            }
                        }

                        & > small {
                            font-style: normal;
                            font-weight: normal;
                            font-size: 12px;
                            line-height: 150%;
                            color: rgba($color-dark, 0.6);
                        }

                        & > span {
                            width: 90px;
                            height: 28px;
                            border: 1px solid $color-green;
                            border-radius: 6px;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 16px;
                            text-align: center;
                            padding: 6px 10px 4px;
                            color: $color-green;
                            display: block;

                            @include bp(md) {
                                margin-top: 5px;
                                border: none;
                                width: 100%;
                                text-align: left;
                                padding: 0;
                                color: $color-dark;
                                text-decoration: underline;
                                font-weight: normal;
                                font-size: 14px;
                                line-height: 150%;
                                margin-bottom: 2px;
                                height: auto;
                                @include anim-f(color);
                            }
                        }
                    }
                }
            }
        }

        &_popup {
            &-data {
                @include bp(md) {
                    display: table;
                    width: auto;
                    margin-top: 20px;
                }

                p {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 150%;

                    @include bp(md) {
                        display: table-row;

                        &:last-child {
                            & > * {
                                padding-bottom: 0;
                                padding-top: 10px;
                            }
                        }

                        & > * {
                            display: table-cell;
                            padding-bottom: 10px;
                            margin: 0;

                            &:last-child {
                                padding-left: 30px;
                            }
                        }
                    }

                    & > * {
                        display: block;
                    }

                    & > small {
                        font-style: normal;
                        font-weight: normal;
                        font-size: inherit;
                        line-height: inherit;
                        color: rgba($color-dark, 0.6);
                        margin-bottom: 2px;

                        @include bp(md) {
                            margin-bottom: 0;
                        }
                    }

                    & + p {
                        margin-top: 10px;
                    }

                    .input_container {
                        width: 172px;
                        margin-top: 10px;
                        margin-bottom: 0;

                        @include bp(md) {
                            margin-top: 0;
                        }
                    }

                    a {
                        text-decoration: underline;

                        &:hover {
                            color: $color-green;
                        }
                    }
                }

                input[type="text"],
                input[type="number"] {
                    background: #f3f3f4;
                    border-color: $color-green;
                    font-weight: 700;
                }
            }

            .page_lk--popup-head ~ p + .form_default--field_group {
                margin-top: 30px;

                @include bp(md) {
                    margin-top: 40px;
                }
            }
        }
    }
}
