/**
В данный файл нужно писать стили для заголовков, списков, параграфов и т.д.
*/

%font-alt{
    font-family: $font2;
    font-feature-settings: 'pnum' on, 'lnum' on;
}

%header-big {
    font-family: $font2;
    font-feature-settings: 'pnum' on, 'lnum' on;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 110%;

    @include bp(lg) {
        font-size: 50px;
    }
}

%header-medium{
    font-family: $font2;
    font-feature-settings: 'pnum' on, 'lnum' on;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 110%;

    @include bp(lg){
        font-size: 36px;
    }

    small{
        font-size: 0.7em;
    }
}

%header-medium2{
    font-family: $font1;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 110%;

    @include bp(lg){
        font-size: 24px;
    }
}

%header-medium3{
    font-family: $font1;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 110%;
}

%list-numerated {
    counter-reset: lst;

    & > li {
        padding-left: em(32, 18);
        position: relative;
        counter-increment: lst;

        &:before {
            content: counter(lst, decimal-leading-zero) ".";
            display: inline-block;
            position: absolute;
            left: 0;
            top: 0;
            font-size: inherit;
            line-height: inherit;
            color: orange;
            font-weight: 600;
        }
    }
}

%list-default {
    & > li {
        margin-bottom: em(10, 16);
        position: relative;
        padding-left: em(14, 16);

        &:last-child {
            margin-bottom: 0;
        }

        &:before {
            @include pseudos;
            position: absolute;
            width: 6px;
            height: 6px;
            left: 0;
            top: em(8, 16);
            background-color: $color-green;
            border-radius: 50%;
        }
    }
}

%collapse-margins {
    & > *:not(.row) {
        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}
