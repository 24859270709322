.page_error {
    @extend %header-transparent;
}

.layout_error {
    position: relative;
    background-color: $color-dark;
    padding: 200px 0 118px;

    @include bp(lg){
        padding: 310px 0 288px;
    }

    &--code {
        display: block;
        font-size: 0;
        line-height: 0;
        text-align: center;

        @include bp(lg){
            text-align: right;
            padding-top: 20px;
        }

        img {
            max-width: 100%;
        }
    }

    .block_text {
        text-align: center;
        color: $color-light;

        @include bp(lg) {
            text-align: left;

            p,
            ul,
            ol {
                max-width: 460px;
            }
        }

        .btn {
            max-width: 320px;
            margin: 30px auto 0;

            @include bp(lg){
                width: 200px;
                margin: 30px 0 0;
            }
        }
    }

    .row {
        margin-bottom: -24px;

        & > * {
            margin-bottom: 24px;
        }
    }
}
