$modalShowDur: 0.3s;

.popup {
    &-root {
        position: fixed;
        //z-index: -999;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        // Костыль для анимации.
        transition: opacity $modalShowDur ease-in-out, z-index 0.001s linear $modalShowDur,
            visibility 0.001s linear $modalShowDur;
        z-index: -999;
        //display: table;
        display: block;
        visibility: hidden;

        &.popup-visible {
            z-index: $z-popup;
            opacity: 1;
            visibility: visible;
            overflow-y: auto;
            transition-delay: 0s;
            animation: fadeIn $modalShowDur ease-out;
        }

        &:not(.popup-visible) {
            animation: none !important;
        }
    }

    &-overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba($color-dark, 0.4);
        @include animation-fix;
        backdrop-filter: blur(3px);
    }

    &-cross {
        width: 30px;
        height: 30px;
        top: 15px;
        right: 15px;
        @include cross(24px, 3px, $color-dark, 0, $color-green);
        position: absolute;
        display: block;
        z-index: 2;

        @include bp(md) {
            top: 20px;
            right: 20px;
        }
    }

    &-container {
        position: relative;
        min-width: 100%;
        min-height: 100%;
        min-height: 100vh;
        z-index: 1;
        line-height: 90vh;
        padding: 5vh 0;
        text-align: center;

        .popup-non-scrollable & {
            padding-right: var(--scrollbarWidth, 17px);
        }
    }

    &-content {
        display: none;
        position: relative;
        z-index: 10;
        //transform: translate3d(0, 0, 0);
        vertical-align: middle;
        margin: 0 auto;
        width: #{(351/375) * 100%};
        text-align: left;
        font-family: $font1;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        background: $color-light;
        box-shadow: 4px 4px 30px rgba(22, 23, 36, 0.2);
        border-radius: 4px;
        padding: 30px 15px;

        @include bp(md) {
            width: 90%;
            padding: 40px;
        }

        .popup-initialized & {
            display: inline-block;
        }

        p {
            strong,
            a[href^="mailto"],
            a[href="tel"] {
                font-weight: 700;
            }

            a[href^="mailto"]:hover,
            a[href="tel"]:hover {
                color: $color-purple;
            }
        }

        .form_default{
            margin-top: 30px;

            @include bp(md){
                margin-top: 20px;
            }
        }
    }

    &-title {
        font-family: $font1;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 110%;
        margin-bottom: 14px;
        display: block;

        @include bp(md) {
            margin-bottom: 20px;
        }
    }
}
