.page_lk{
    &--question{
        .page_lk--layout-section-header{
            margin-bottom: 14px;

            @include bp(md){
                margin-bottom: 20px;
            }
        }

        .form_default{
            margin-top: 20px;

            @include bp(md){
                margin-top: 26px;
            }

            @include bp-to(md){
                textarea{
                    height: 100px;
                }
            }
        }
    }
}