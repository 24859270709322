.form_default {
    #{$text-inputs} {
        background: transparent;
        border-radius: 7px;
        border: 1px solid rgba($color-purple, 0.2);
        padding: 12px 13px;
        height: 40px;
        width: 100%;
        @extend %form-label;
        background-color: $color-light;
        box-shadow: none;
        appearance: none;
        @include anim-f(border);

        &:focus,
        &:hover {
            border-color: $color-purple !important;
        }

        &.validation-touched:invalid {
            border-color: $color-red;
        }

        // &.validation-touched:valid{
        //     border-color: $color-green;
        // }

        &.clear {
            border-color: transparent !important;
            background-color: transparent;

            &::placeholder {
                color: inherit;
            }
        }
    }

    input[type="number"] {
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            visibility: hidden;
            appearance: none;
        }
    }

    textarea {
        resize: none;
        height: 150px;
    }

    &--card_field {
        padding-right: 60px !important;
        background-position: calc(100% - 12px) 50% !important;
        background-repeat: no-repeat !important;
    }

    /*Галочка в input*/
    .is-valid {
        border-color: #28a745;
        padding-right: calc(1.5em + 0.75rem);
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right calc(0.375em + 0.1875rem) center;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }
    /*Восклицательный знак в input*/
    .is-invalid {
        border-color: #dc3545;
        padding-right: calc(1.5em + 0.75rem);
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right calc(0.375em + 0.1875rem) center;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }
}
