.tooltip {
    &-trigger {
        font-style: normal !important;
        display: inline-block !important;
        vertical-align: top !important;

        &:before {
            @include pseudos;
            @extend %icon-base;
            @extend %icon-info;
            width: 10px;
            height: 10px;
            border: 1px solid rgba($color-dark, 0.2);
            border-radius: 50%;
            background-position: 50% 50%;
            background-size: auto;
        }

        &:after{
            margin-top: 5px !important;

            @include bp(md){
                margin-right: -23px !important;
            }
        }
    }
}

[data-tooltip] {
    filter: drop-shadow(4px 4px 20px #{rgba($color-purple, 0.2)});
    position: relative;
    z-index: 3;

    &:after {
        content: attr(data-tooltip);
        width: 200px;
        height: auto;
        position: absolute;
        display: block;
        top: 100%;
        right: 50%;
        margin-right: -100px;
        font-size: 12px;
        line-height: 1.5em;
        padding: 20px 14px 10px;
        background-color: $color-light;
        color: $color-dark;
        font-style: normal;
        font-weight: normal;
        clip-path: polygon(
            0 10px,
            calc(50% - 10px) 10px,
            50% 0,
            calc(50% + 10px) 10px,
            100% 10px,
            100% 100%,
            0 100%
        );
        text-align: left;
        z-index: 10;
        opacity: 0;
        visibility: hidden;
        transition: visibility 0.001s linear 0.5s, opacity 0.3s ease 0.2s;
        margin-top: 10px;

        @include bp(md) {
            width: 240px;
            right: 0;
            font-size: 14px;
            line-height: 150%;
            margin-right: 0;
            padding: 29px 18px 20px;
            clip-path: polygon(
                0 10px,
                calc(100% - 38px) 10px,
                calc(100% - 28px) 0,
                calc(100% - 18px) 10px,
                100% 10px,
                100% 100%,
                0 100%
            );
        }
    }

    &:focus,
    &:hover {
        &:after {
            opacity: 1;
            visibility: visible;
            transition-delay: 0s;
        }
    }

    &.btn {
        &:after {
            @include bp(md){
                right: 0;
            }
        }
    }
}
