.block_hero {
    position: relative;
    overflow: hidden;
    z-index: 1;
    color: $color-light;

    .wrapper {
        min-height: 100vh;
        padding-top: 106px;

        @include bp(lg) {
            padding-top: 190px;
            min-height: 800px;
            height: 100vh;
            padding-bottom: 90px;
        }
    }

    &--bg {
        z-index: -1;
        @include stretch;

        @include bp-to(lg) {
            height: auto;
            top: 0;
            bottom: 96px;
        }

        img {
            position: absolute;
            display: block;
            width: 100%;
            height: 120%;
            top: -20%;
            object-position: 40% 0;
            object-fit: cover;
            z-index: 1;

            @include bp(md, lg) {
                top: -35%;
                height: 135%;
            }

            @include bp(lg) {
                object-position: 50% 50%;
                height: 120%;
                top: -10%;
            }
        }

        &:before {
            @include pseudos;
            @include stretch;
            z-index: 2;
            background: linear-gradient(89.43deg, rgba(0, 0, 0, 0.6) 0.53%, rgba(0, 0, 0, 0) 170.31%);
            mix-blend-mode: darken;

            @include bp(lg) {
                background: linear-gradient(90deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 71.87%);
            }
        }

        &:after {
            @include pseudos;
            @include stretch;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 49.89%);
            mix-blend-mode: darken;
            z-index: 3;

            @include bp(lg) {
                background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) -8.48%, rgba(0, 0, 0, 0) 15.65%);
            }
        }
    }

    &--text {
        @include bp(lg) {
            padding-top: 110px;
            margin-right: 460px;
            min-height: 500px;
        }

        @include bp(xl) {
            margin-right: 50%;
        }

        h1 {
            @extend %header-big;
            margin-bottom: 20px;
        }

        ul {
            @extend %list-default;

            li {
                &:before {
                    background-color: $color-light;
                }
            }
        }

        @include anim-m(opacity, transform);
        transition-delay: 0.2s;
        transition-timing-function: ease-out;
        opacity: 1;

        body.preload & {
            opacity: 0;
            transform: translateX(-200px);
        }
    }

    &--form {
        background: $color-light;
        box-shadow: 4px 4px 30px rgba(22, 23, 36, 0.1);
        border-radius: 8px;
        width: 100%;
        max-width: 440px;
        margin: 0 auto 170px;
        padding: 40px 18px;
        color: $color-dark;

        @include bp(lg) {
            position: absolute;
            margin: 0;
            right: 0;
            top: 190px;
            padding: 40px;
        }

        .nouislider {
            margin-bottom: 30px;
        }

        &-total {
            margin-top: 30px;
            position: relative;

            .calculate_conditions {
                position: relative;
                display: block;
                color: rgba(22,23,36,0);
                font-size: 0.8em;
                top: -1.5em;
                height: 0;
                overflow: hidden;
                transition: color 0.5s linear, height 0.5s 0.5s linear;

                &--expanded {
                    color: rgba(22,23,36,1);
                    height: 20px;
                    transition: height 0.5s linear, color 0.5s 0.5s linear;
                }
            }

            p {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-end;
                margin-bottom: 17px;

                &:last-child {
                    margin-bottom: 0;
                }

                & > * {
                    width: 48%;
                    margin-right: 4%;

                    @include bp(md) {
                        width: 160px;
                        margin-right: 0;
                    }

                    &:nth-child(2n) {
                        margin-right: 0;
                    }
                }

                & > small {
                    font-family: $font1;
                    font-style: normal;
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 19px;
                    color: rgba($color-dark, 0.6);
                }

                & > span {
                    font-size: 0;
                    line-height: 0;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: baseline;

                    & > * {
                        font-family: $font1;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 22px;
                    }

                    small {
                        font-size: 0.8em;
                    }

                    img {
                        margin-right: 15px;
                        max-width: calc(33.333% - 10px);

                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }
        }

        opacity: 1;
        @include anim-m(opacity);
        transition-delay: 0.5s;

        body.preload & {
            opacity: 0;
        }
    }

    &--current {
        margin-top: 40px;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;

        @include bp(sm) {
            justify-content: flex-start;
        }

        @include bp(lg) {
            margin: 0;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;

            &:before {
                display: none !important;
            }
        }

        &:before {
            @include pseudos;
            position: absolute;
            width: 100vw;
            height: 100%;
            left: 50%;
            margin-left: -50vw;
            top: 0;
            background-color: $color-purple;
            z-index: -1;
        }

        p {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 48%;
            margin-right: 4%;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 120%;
            padding: 25px 0 22px;
            max-width: 150px;

            @include bp(lg) {
                width: 165px;
                margin-right: 50px;
                font-size: 14px;
                line-height: 130%;
                max-width: none;
            }

            &:nth-child(2n) {
                margin-right: 0;
            }

            strong {
                display: block;
                font-style: normal;
                font-weight: bold;
                font-size: 32px;
                line-height: 44px;
                margin-right: 8px;
                padding-top: 5px;

                @include bp(lg) {
                    font-size: 36px;
                    line-height: 49px;
                    margin-right: 14px;
                }
            }

            span {
                display: block;
            }
        }

        @include anim-m(opacity, transform);
        transform: translateY(0);
        opacity: 1;
        transition-delay: 0.7s;

        body.preload & {
            opacity: 0;
            transform: translateY(100px);
        }
    }
}
