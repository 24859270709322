.page_header_alt {
    position: absolute;
    z-index: $z-header;
    width: 100%;
    left: 0;
    top: 0;

    @include bp(xl) {
        position: static;
        z-index: initial;

        &:before{
            @include pseudos;
            position: absolute;
            width: 100%;
            height: $header-big;
            background-color: $color-light;
            box-shadow: 0px 4px 20px rgba($color-purple, 0.06);
            z-index: 1;
            top: 0;
            left: 0;
        }

        & ~ main {
            min-height: 713px;
            position: relative;

            &:before {
                @include pseudos;
                background: $color-light;
                box-shadow: 0px 4px 20px rgba(109, 38, 100, 0.06);
                position: absolute;
                top: 0;
                left: 50%;
                margin-left: -50vw;
                width: calc(50vw - #{$wrapper-xl/2 - 267px});
                height: 100%;
                z-index: 2;
            }
        }
    }

    &--placeholder {
        height: $header-small-expanded;

        @include bp(xl) {
            height: $header-big;
        }
    }

    &--main {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: $header-small-expanded;

        @include bp(xl) {
            height: 0;
            display: block;
            z-index: $z-header;
        }

        .wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        &-burger {
            width: 36px;
            height: 36px;
            position: relative;
            display: block;
            $burger-line-width: 27px;
            $burger-line-thickness: 3px;
            $burger-line-gap: 5px + $burger-line-thickness;
            $burger-color: $color-dark;

            @include bp(xl) {
                display: none;
            }

            &:before,
            &:after {
                @include pseudos;
                width: $burger-line-width;
                height: $burger-line-thickness;
                background-color: $burger-color;
                position: absolute;
                top: 50%;
                left: 50%;
                margin: #{-$burger-line-thickness/2} #{-$burger-line-width/2};
                transform-origin: 50% 50%;
                @include anim-f(background-color);
            }

            &:before {
                box-shadow: 0 $burger-line-gap 0 0 $burger-color, 0 #{-$burger-line-gap} 0 0 $burger-color;
            }

            span {
                display: block;
                visibility: hidden;
                opacity: 0;
                @include anim-f(opacity);
                position: fixed;
                width: 100vw;
                height: 100vh;
                top: 0;
                left: 0;
                z-index: 1;
                background-color: rgba($color-dark, 0.4);
                @include animation-fix;
                backdrop-filter: blur(3px);
                pointer-events: none;
            }

            .expanded & {
                span {
                    visibility: visible;
                    pointer-events: all;
                    opacity: 1;
                }
            }
        }

        &-title {
            display: block;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 25px;
            margin: 0 auto;
            text-align: center;

            @include bp(xl) {
                display: none;
            }
        }

        &-notifications {
            // position: relative;
            display: block;

            @include bp(xl) {
                position: absolute;
                top: 41px;
                right: 160px;
            }

            // &:focus,
            // &:hover {
            //     & > button {
            //         svg {
            //             fill: $color-green;
            //         }

            //         & + * {
            //             visibility: visible;
            //             opacity: 1;
            //             transition-delay: 0s;
            //         }
            //     }
            // }

            @include bp(md) {
                &:focus,
                &:hover {
                    & > button {
                        svg {
                            fill: $color-green;
                        }

                        & + * {
                            visibility: visible;
                            opacity: 1;
                            transition-delay: 0s;
                        }
                    }
                }
            }

            & > button {
                width: 26px;
                height: 26px;
                display: block;
                position: relative;

                svg {
                    @include centre;
                    @include svgcolor($color-dark);
                }

                &.new {
                    &:after {
                        @include pseudos;
                        width: 7px;
                        height: 7px;
                        border-radius: 50%;
                        background-color: $color-green;
                        position: absolute;
                        top: 3px;
                        right: 3px;
                    }
                }

                &:hover {
                    svg {
                        fill: $color-green;
                    }
                }

                &:focus {
                    svg {
                        fill: $color-green;
                    }

                    & + * {
                        visibility: visible;
                        opacity: 1;
                        transition-delay: 0s;
                    }
                }
            }

            &-container {
                visibility: hidden;
                position: fixed;
                width: 100vw;
                height: calc(100vh - #{$header-small-expanded});
                top: $header-small-expanded;
                left: 0;
                z-index: 3;
                background-color: $color-light;
                opacity: 0;
                transition: visibility 0.001s linear 0.3s, opacity 0.3s ease;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: stretch;

                @include bp(md) {
                    position: absolute;
                    right: -15px;
                    width: 402px;
                    top: 40px;
                    left: auto;
                    height: auto;
                    box-shadow: 4px 4px 20px rgba($color-purple, 0.25);
                    transition: visibility 0.001s linear 0.5s, opacity 0.3s ease 0.2s;

                    &:before {
                        @include pseudos;
                        @include triangle(up, 20px, 10px, $color-light);
                        position: absolute;
                        bottom: 100%;
                        right: 18px;
                    }
                }
            }

            &-title {
                display: flex;
                width: 100%;
                height: $header-small-expanded;
                position: absolute;
                background-color: $color-light;
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 25px;
                text-align: center;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 0 58px;
                bottom: 100%;
                left: 0;
                // pointer-events: none;

                @include bp(md) {
                    position: relative;
                    display: block;
                    text-align: left;
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 150%;
                    padding: 20px 18px;
                    color: $color-green;
                    height: auto;
                }

                &:before {
                    @include pseudos;
                    @extend %icon-base;
                    @extend %icon-arrow-left;
                    @include centre(y);
                    left: 33px;

                    @include bp(md) {
                        display: none;
                    }
                }
            }

            &-list {
                border-top: 1px solid rgba($color-purple, 0.2);
                overflow-y: auto;
                overflow-x: hidden;

                @include bp(md) {
                    max-height: 500px;
                }

                p {
                    padding: 14px 28px;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 150%;
                    border-bottom: 1px solid rgba($color-purple, 0.2);

                    @include bp(md) {
                        padding: 14px 18px;
                    }

                    &.new {
                        background-color: rgba($color-purple, 0.05);
                    }

                    a {
                        text-decoration: underline;

                        &:hover {
                            color: $color-green;
                        }
                    }

                    time {
                        display: block;
                        margin-top: 4px;
                        opacity: 0.5;
                    }
                }
            }
        }
    }

    &--menu {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        height: vh(100);
        transform: translateX(-100%);
        width: auto;
        visibility: hidden;
        transition: visibility 0.001s linear 0.3s, transform 0.3s ease-out;
        width: 315px;
        background-color: $color-light;

        @include bp(xl) {
            position: absolute;
            height: 0;
            width: 100%;
            transform: none;
            visibility: visible;
            background-color: transparent;
            z-index: $z-header;
        }

        .expanded & {
            transition-delay: 0s;
            transform: translateX(0);
            visibility: visible;
        }

        .wrapper {
            @include bp-to(xl) {
                width: auto;
                margin: 0 28px;
            }
        }

        &-scroll {
            @include bp-to(xl) {
                max-height: 100%;
                overflow-y: auto;
                overflow-x: hidden;
                width: 100%;
                height: 100%;
            }
        }

        &-left {
            padding-top: 36px;

            @include bp(xl) {
                position: absolute;
                top: $header-big;
                width: 237px;
                padding: 50px 0 0 10px;
            }

            &-name {
                display: block;
                font-family: $font2;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 120%;
                margin-bottom: 4px;

                @include bp(xl) {
                    font-size: 18px;
                    line-height: 120%;
                    margin-bottom: 6px;
                }
            }

            &-tel {
                display: block;
                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 150%;
                color: rgba($color-purple, 0.4);
            }

            &-nav {
                padding-top: 28px;
                margin-top: 20px;
                position: relative;

                @include bp(xl){
                    margin-right: -30px;
                }

                &:before {
                    @include pseudos;
                    width: 315px;
                    left: -28px;
                    position: absolute;
                    height: 1px;
                    top: 0;
                    background-color: rgba($color-purple, 0.2);

                    @include bp(xl) {
                        width: 237px;
                        left: -10px;
                    }
                }

                a {
                    display: flex;
                    position: relative;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 150%;
                    @include anim-f(all);
                    width: 100%;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    z-index: 1;
                    height: 48px;
                    margin-bottom: 12px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    svg {
                        font-size: 20px;
                        @include svgcolor($color-dark);
                        margin-right: 14px;
                    }

                    &:before {
                        @include pseudos;
                        position: absolute;
                        width: auto;
                        height: 100%;
                        background-color: $color-light;
                        z-index: -1;
                        top: 0;
                        left: 0;
                        right: -28px;
                        box-shadow: 1px 2px 14px rgba($color-purple, 0.12);
                        border-radius: 2px;
                        border-left: 3px solid $color-purple;
                        opacity: 0;
                        @include anim-f(opacity);
                        pointer-events: none;

                        @include bp(xl) {
                            // right: -30px;
                            right: 0;
                            left: -10px;
                        }
                    }

                    .new {
                        display: inline-block;
                        font-size: 0;
                        line-height: 0;
                        width: 5px;
                        height: 5px;
                        border-radius: 50%;
                        background-color: $color-green;
                        margin-left: 3px;
                        margin-top: -10px;
                    }

                    &.active,
                    &:hover {
                        color: $color-purple;

                        svg {
                            fill: $color-purple;
                        }
                    }

                    &.active {
                        padding-left: 30px;
                        font-weight: bold;

                        @include bp(xl){
                            padding-left: 16px;
                        }

                        &:before {
                            opacity: 1;
                        }
                    }

                    &.disabled {
                        pointer-events: none;
                        opacity: 0.5;
                    }
                }
            }
        }

        &-log_out {
            margin: 38px 0 70px;

            @include bp(xl) {
                margin: 0;
                width: 130px;
                position: absolute;
                top: 32px;
                right: 0;

                .btn {
                    padding: 10px;
                    min-width: 130px;
                }
            }

            .btn {
                color: $color-dark !important;

                &:hover {
                    color: $color-light !important;
                }
            }
        }

        &-bottom {
            position: relative;
            padding: 60px 0 30px;
            z-index: 1;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;

            @include bp(xl) {
                position: absolute;
                padding: 0;
                z-index: initial;
                height: 0;
                top: 0;
                left: 0;
                width: 100%;
                display: block;

                &:before {
                    display: none;
                }
            }

            &:before {
                @include pseudos;
                width: auto;
                left: -28px;
                right: -28px;
                top: 0;
                height: 100%;
                position: absolute;
                background-color: rgba($color-purple, 0.05);
                z-index: -1;
            }
        }

        &-logo {
            transform: translateX(-22px);
            margin-bottom: 30px;

            @include bp(xl) {
                position: absolute;
                left: -30px;
                top: 35px;
            }
        }

        &-nav {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 150%;
            text-align: center;

            @include bp(xl) {
                flex-direction: row;
                width: 680px;
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;
                align-items: flex-start;
                left: 267px;
                top: 62px;
                position: absolute;
            }

            a {
                margin-bottom: 26px;

                @include bp(xl) {
                    margin-bottom: 0;
                    margin-right: 35px;

                    &:last-child {
                        margin-right: 0;
                    }
                }

                &:hover {
                    color: $color-green;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        &-contacts {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            margin-top: 40px;
            text-align: center;
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 110%;

            @include bp(xl) {
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;
                position: absolute;
                margin: 0;
                left: 267px;
                top: 30px;
                font-weight: 800;
                font-size: 15px;
                line-height: 20px;

                a {
                    margin-bottom: 0;
                    margin-right: 35px;
                }
            }

            a {
                margin-bottom: 14px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
