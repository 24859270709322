.applepay{
    font-size: 16px;
    width: (38.97/16)*1em;
}
.gosuslugi{
    font-size: 34.7px;
    width: (220/34.7)*1em;
}
.icon-attachment{
    font-size: 18px;
    width: (18/18)*1em;
}
.icon-camera{
    font-size: 18px;
    width: (18/18)*1em;
}
.icon-file{
    font-size: 20px;
    width: (20/20)*1em;
}
.icon-notifications{
    font-size: 21px;
    width: (18/21)*1em;
}
.icon-plus{
    font-size: 24px;
    width: (24/24)*1em;
}
.icon-visibility-off{
    font-size: 20px;
    width: (22/20)*1em;
}
.icon-visibility-on{
    font-size: 15px;
    width: (22/15)*1em;
}
.lk-card{
    font-size: 20px;
    width: (20/20)*1em;
}
.lk-edit{
    font-size: 20px;
    width: (20/20)*1em;
}
.lk-glav{
    font-size: 236px;
    width: (232/236)*1em;
}
.lk-graph{
    font-size: 20px;
    width: (20/20)*1em;
}
.lk-history{
    font-size: 20px;
    width: (20/20)*1em;
}
.lk-question{
    font-size: 20px;
    width: (20/20)*1em;
}
.lk-secur{
    font-size: 20px;
    width: (20/20)*1em;
}
