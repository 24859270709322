$transition-speed-fast: 0.2s;
$transition-speed-moderate: 0.4s;
$transition-speed-slow: 1s;
$default-transition-easing: ease-in-out;

$i: 0;
$n: 0;
@while $n < 15 {
    $i: $i + 0.1;
    $n: $n + 1;
    %delay-#{$n} {
        animation-delay: #{$i}s;
    }
}

@mixin animation-fix() {
    backface-visibility: hidden;
    transform: translateZ(0) scale(1, 1);
}

@mixin transitionList($duration, $options...) {
    transition-property: $options;
    transition-duration: $duration;
    transition-timing-function: $default-transition-easing;
}

@mixin anim-f($options...) {
    @include transitionList($transition-speed-fast, $options);
}

@mixin anim-m($options...) {
    @include transitionList($transition-speed-moderate, $options);
}

@mixin anim-s($options...) {
    @include transitionList($transition-speed-slow, $options);
}

$elasticEase: cubic-bezier(0.13, 1.74, 0.3, 0.82);