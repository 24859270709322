.form_default {
    input[type="file"] {
        @include hideField;

        & ~ .input_container--label {
            display: block;
            pointer-events: all;
            position: relative;
            left: 0;
            top: auto;
            transform: none !important;
            color: $color-dark;
            padding-left: 28px;
            @include anim-f(color);
            cursor: pointer;
            opacity: 1;

            @include bp(md) {
                max-width: 50%;
                float: left;
            }

            svg {
                @include svgcolor($color-dark);
                position: absolute;
                left: 0;
                top: 3px;
            }

            small {
                font-family: $font1;
                font-style: normal;
                font-weight: normal;
                font-size: 10px;
                line-height: 150%;
                opacity: 0.5;
                display: block;
                margin-top: 2px;
            }
        }

        &:focus ~ .input_container--label,
        & ~ .input_container--label:hover {
            color: $color-green;

            svg {
                fill: $color-green;
            }
        }

        & ~ .input_container--files {
            display: inline-block;
            margin-left: 28px;
            font-family: $font1;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 150%;
            color: $color-dark;
            position: relative;
            pointer-events: all;
            max-width: calc(100% - 28px);
            margin-top: 20px;
            padding-right: 13px;

            @include bp(md) {
                padding-left: 0;
                max-width: 50%;
                float: right;
                margin-top: 0;
                margin-left: 0;
            }

            span {
                display: block;
                margin-top: 8px;

                &:first-of-type {
                    margin-top: 0;
                }
            }

            button {
                display: block;
                width: 12px;
                height: 12px;
                @include cross(10px, 1px, $color-dark, 0, $color-green);
                position: absolute;
                right: 0;
                top: -8px;
                z-index: 1;
            }
        }
    }
}
