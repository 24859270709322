@mixin pseudos {
    content: "";
    display: block;
}

%pseudos {
    content: "";
    position: absolute;
}

%safe-text-overflows {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

@mixin centre($axis: "both", $withZ: false) {
    position: absolute;
    $transform: "";

    @if $axis == "y" {
        top: 50%;
        $transform: "translateY(-50%)";
    }
    @if $axis == "x" {
        left: 50%;
        $transform: "translateX(-50%)";
    }
    @if $axis == "both" {
        top: 50%;
        left: 50%;
        $transform: "translateX(-50%) translateY(-50%)";
    }

    @if ($withZ) {
        $transform: $transform + " translateZ(0)";
    }

    transform: #{$transform};
}

@mixin svgcolor($color: black) {
    transition: fill 0.2s ease-in-out, stroke 0.2s ease-in-out;
    fill: $color;
    stroke: $color;
}

@mixin cross($width, $thickness, $color, $round: 0, $hoverColor: $color) {
    position: relative;

    &:before,
    &:after {
        @include pseudos;
        position: absolute;
        transform-origin: 50% 50%;
        left: 50%;
        top: 50%;
        width: $width;
        height: $thickness;
        background-color: $color;
        border-radius: $round;
        @include anim-f(background);
    }

    &:before {
        transform: translateX(-50%) translateY(-50%) translateZ(0) rotate(-45deg);
    }

    &:after {
        transform: translateX(-50%) translateY(-50%) translateZ(0) rotate(45deg);
    }

    &:hover {
        &:before,
        &:after {
            background-color: $hoverColor;
        }
    }
}

@mixin qq($n) {
    &:nth-last-child(#{$n}):first-child {
        &,
        & ~ * {
            @content;
        }
    }
}

@mixin arrow($size, $thickness, $color, $direction) {
    width: $size;
    height: $size;
    border-style: solid;
    border-color: $color;
    transform-origin: 50% 50%;
    transform: rotate(-45deg) translateZ(0);

    @if ($direction == "up") {
        border-width: $thickness $thickness 0 0;
    }

    @if ($direction == "down") {
        border-width: 0 0 $thickness $thickness;
    }

    @if ($direction == "left") {
        border-width: $thickness 0 0 $thickness;
    }

    @if ($direction == "right") {
        border-width: 0 $thickness $thickness 0;
    }
}

@mixin tintbg($opacity) {
    .parallax-bg,
    .bg {
        &:before {
            @include pseudos;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, $opacity);
        }
    }
}

@mixin hideField() {
    opacity: 0;
    width: 0.1px;
    height: 0.1px;
    overflow: hidden;
    position: absolute;
    z-index: -100;
}

@mixin beforePageLoad() {
    body.preload & {
        @content;
    }
}

@mixin afterPageLoad() {
    body:not(.preload) & {
        @content;
    }
}

@mixin stretch {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0.4375rem;
}

@mixin text-stroke($width, $color) {
    text-shadow: -#{$width} -#{$width} 0 $color, #{$width} -#{$width} 0 $color, -#{$width} #{$width} 0 $color,
        #{$width} #{$width} 0 $color;
}

@function vh($height) {
    @return calc(var(--vh, 1vh) * #{$height});
}

@mixin aspect-ratio($width, $height) {
    position: relative;

    &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: ($height / $width) * 100%;
    }

    & > * {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
    }
}

@mixin clearfix {
    &:after {
        content: "";
        width: 100%;
        height: 0.001px;
        display: table;
        clear: both;
    }
}
