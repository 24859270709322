[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
[v-cloak],
[x-cloak],
.display-none {
    display: none !important;
}

.clear:before,
.clear:after {
    content: " ";
    display: table;
}

.clearfix:after {
    content: " ";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}

.preload * {
    transition: none !important;
}

a {
    text-decoration: none;
    color: inherit;
    @include anim-f(color);

    &[href^="tel:"],
    &[href^="mailto:"] {
        white-space: nowrap;
    }
}

.scroll-disabled {
    overflow: hidden;
    height: 100%;
    width: 100%;

    &:not(.is-safari) {
        @include bp(xl) {
            .page_header--main,
            .page_header--menu,
            .page_header_alt--main,
            .page_header_alt--menu,
            .page_footer,
            main {
                padding-right: var(--scrollbarWidth, 17px);
            }
        }
    }

    &.is-safari.touch-device{
        position: fixed;
        left: 0;
        top: 0;
    }
}

.debug-message {
    display: none;
    font-size: 0.8em;
    line-height: 1.2em;
    color: black;
    background: white;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

* {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

input {
    border-radius: 0;
    appearance: none;
    box-shadow: none;
}
